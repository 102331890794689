import ScrollArea from 'react-scrollbar';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
import RoomReservationListItem from './RoomReservationListItem';

const RoomReservationList = ({
    roomNumber,
    reservationTimesData,
    allGuideReservations,
    setSelectedCustomer,
    OnApproveEnter,
    OnRemoveUser,
    isModerator,
    waitingUsers,
    users
}) => {

    const helper = new Helper();

    var resBlocks = [];
    var specificTimeRes = [];
    var anytimeRes = [];
    var justListeningRes = [];
    var individualRes = [];

    if(reservationTimesData && allGuideReservations)
    {
        for(let r of allGuideReservations)
        {
            var d = r.resDate.split(" ");
            var m = helper.GetMinutesFromTime(d[1]);

            var resData = {
                guideReservationID: r.guideReservationID,
                customerID:r.customerID,
                customer:r.customer,
                resDate:r.resDate,
                confirmed: r.confirmed,
                isOnline: false
            }

            for(let u of users)
            {
                if(r.customerID == u.customerID)
                    resData.isOnline = true;
            }

            if(r.guideReservationTypeID == 1)
                specificTimeRes.push(resData);
            if(r.guideReservationTypeID == 2)
                anytimeRes.push(resData);
            if(r.guideReservationTypeID == 3)
                justListeningRes.push(resData);
            if(r.guideReservationTypeID == 4)
                individualRes.push(resData);      
        }

        for(let val of reservationTimesData.GuideReservationDayDataForRoom)
        {
            var timeF = helper.GetMinutesFromTime(val.timeFrom);
            var timeT = helper.GetMinutesFromTime(val.timeTo);

            for(let i = timeF; i < timeT; i += val.blockLength)
            {
                var customers = [];
                
                for(let r of specificTimeRes)
                {
                    var d = r.resDate.split(" ");
                    var m = helper.GetMinutesFromTime(d[1]);

                    if(m == i)
                        customers.push(r)
                }

                resBlocks.push({
                    guideReservationTimeID: val.guideReservationTimeID,
                    timeFrom: i,
                    customers,
                    timeFromStr: helper.GetTimeFromMinutes(i),
                    timeToStr: helper.GetTimeFromMinutes(i + val.blockLength)
                })
            }
        }
    }

    return(
        <div className="side-bar">
            <ScrollArea
                smoothScrolling={true}
            >
                <div className="side-bar-content"> 
                    {waitingUsers.length > 0 ?
                        <>
                            <h2>{translate.t("WaitingForApproval")}</h2>

                            <ul>
                                {waitingUsers.map((item,index) => {
                                    
                                    return (
                                        <li key={index}>
                                            <a href="" onClick={(e) => {e.preventDefault(); setSelectedCustomer(item.customerID)}}>{(item.astralName != "" ? item.astralName : (item.roomName != "" ? item.roomName : item.name + " " + item.surname))}</a>
                                            <a href="" onClick={(e) => OnApproveEnter(e,item.customerID)} className="ml-auto btn btn-primary approval">{translate.t("Approval")}</a>
                                        </li>
                                    )
                                    
                                })}
                            </ul>
                        </>
                    :null}

                    {reservationTimesData && reservationTimesData.GuideReservationDayDataForRoom.length == 0 && users.length > 0 ?
                        <>
                            <h2>{translate.t("ApprovalUsers")}</h2>
                            <ul>
                                {users.map((item,index) => {
                                    item.isOnline = true;
                                    item.customer = {
                                        name: item.name,
                                        surname: item.surname,
                                        astralName: item.astralName,
                                        roomName: item.roomName,
                                    }
                                    if(!item.isModerator)
                                        return(
                                            <li key={item.customerID + "-" + index}>
                                                <RoomReservationListItem
                                                    roomNumber = {roomNumber}
                                                    isModerator = {isModerator}
                                                    item = {item}
                                                    setSelectedCustomer = {(customerID) => setSelectedCustomer(customerID)} 
                                                    OnRemoveUser = {OnRemoveUser}
                                                />
                                            </li>
                                        )
                                    else
                                        return null;
                                })}
                            </ul>
                        </>
                    :
                        <>
                            <h2>{translate.t("SpecificTime")}</h2>
                            <div className="res-blocks room form-group">
                                {resBlocks.map((item,index) => {
                                    return(
                                        <div 
                                            key = {index}
                                            className = {"block info"}
                                        >
                                            <span>{item.timeFromStr}</span>
                                            {resBlocks.length - 1 == index ?
                                                <span className="bottom">{item.timeToStr}</span>
                                            :null}

                                            <ul>
                                                {item.customers.map((item,ind) => (
                                                    <li key={item.customerID + "-" + ind}>
                                                        <RoomReservationListItem
                                                            roomNumber = {roomNumber}
                                                            isModerator = {isModerator}
                                                            item = {item}
                                                            resBlocks = {resBlocks}
                                                            setSelectedCustomer = {(customerID) => setSelectedCustomer(customerID)} 
                                                            OnRemoveUser = {OnRemoveUser}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                })}
                            </div>
                            <h2>{translate.t("Anytime")}</h2>
                            <ul>
                                {anytimeRes.map((item,index) => (
                                    <li key={item.customerID + "-" + index}>
                                        <RoomReservationListItem
                                            roomNumber = {roomNumber}
                                            isModerator = {isModerator}
                                            item = {item}
                                            resBlocks = {resBlocks}
                                            setSelectedCustomer = {(customerID) => setSelectedCustomer(customerID)} 
                                            OnRemoveUser = {OnRemoveUser}
                                        />
                                    </li>
                                ))}
                            </ul>
                            <h2>{translate.t("JustListening")}</h2>
                            <ul>
                                {justListeningRes.map((item,index) => (
                                    <li key={item.customerID + "-" + index}>
                                        <RoomReservationListItem
                                            roomNumber = {roomNumber}
                                            isModerator = {isModerator}
                                            item = {item}
                                            resBlocks = {resBlocks}
                                            setSelectedCustomer = {(customerID) => setSelectedCustomer(customerID)} 
                                            OnRemoveUser = {OnRemoveUser}
                                        />
                                    </li>
                                ))}
                            </ul>
                            <h2>{translate.t("IndividualGuidance")}</h2>
                            <ul>
                                {individualRes.map((item,index) => (
                                    <li key={item.customerID + "-" + index}>
                                        <RoomReservationListItem
                                            roomNumber = {roomNumber}
                                            isModerator = {isModerator}
                                            item = {item}
                                            resBlocks = {resBlocks}
                                            setSelectedCustomer = {(customerID) => setSelectedCustomer(customerID)} 
                                            OnRemoveUser = {OnRemoveUser}
                                        />
                                    </li>
                                ))}
                            </ul> 
                        </>
                    }
                </div>
                
            </ScrollArea>
        </div>
    )
}

export default RoomReservationList;