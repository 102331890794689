import NoMicrophone from '../../../Public/Images/Icons/no-microphone.webp';

const ParticipantLabel = ({name,isMuted}) => {
    return(
        <div className="user-info">
            {isMuted ?
                <img className="no-microphone" src={NoMicrophone} />
            :null}
            {name}
        </div>
    )
}

export default ParticipantLabel;