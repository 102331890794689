import React, { useEffect, useState,useRef } from 'react';
import {withApollo,useMutation,useQuery } from 'react-apollo';
import translate from '../../Translations/index';
import RoomLib from '../Library/room';
import NoItems from '../../../GlobalComponents/NoItems';
import { UseSocket } from '../../Socket/Components/Socket';
import { GET_CUSTOMER_DETAILS } from '../../Customer/Queries/customer';
import { GET_RESERVATION_TIMES_FOR_ROOM, ADD_EDIT_RESERVATION_TIMES } from '../../Reservations/Queries/guideReservation';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Notification from '../../../Library/notification';

const RoomsSettings = (props) => {

    const days = [{
        day:1,
        name:"Pondělí"
    },{
        day:2,
        name:"Úterý"
    },{
        day:3,
        name:"Středa"
    },{
        day:4,
        name:"Čtvrtek"
    },{
        day:5,
        name:"Pátek"
    },{
        day:6,
        name:"Sobota"
    },{
        day:7,
        name:"Neděle"
    },]

    var roomLib  = new RoomLib(props);

    const [resDateError,SetDateError] = useState("");
    const [resDateData,SetResDateData] = useState([]);
    const [allSocketRooms, setAllRooms] = useState(null);

    const {data} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const {data:resTimesData,loading,refetch} = useQuery(GET_RESERVATION_TIMES_FOR_ROOM,{
        fetchPolicy: 'network-only',
    });

    const [AddEditData] = useMutation(ADD_EDIT_RESERVATION_TIMES,{
        onCompleted:() => {

            refetch();

            const notify = new Notification();
            notify.setNotification(null,translate.t("YouHaveItSetUp"),true,false,props.client);
        }
    });

    const {GetAllRooms,allRooms,CloseRoom,socket} = UseSocket();

    useEffect(() => {
		if(socket)
		{
			GetAllRooms();
		}
	},[socket])

    useEffect(() => {
        if(allRooms){
           
            var allR = [];
            for(let i in allRooms.rooms){
                allR.push({
                    name: roomLib.getRoomName(i),
                    hash:i,
                    isOpen:allRooms.rooms[i].isOpen
                })
            }

            setAllRooms(allR);      
        }

    },[allRooms])

    useEffect(() => {

        if(resTimesData)
        {
            var initResData = [];
            for(let val of resTimesData.GuideReservationTimesForRoom)
            {
                var timeFrom = val.timeFrom.split(":");
                var timeTo = val.timeTo.split(":");

                initResData.push({
                    day:val.day,
                    timeFrom:timeFrom[0] + ":" + timeFrom[1],
                    timeTo:timeTo[0] + ":" + timeTo[1],
                    blockLength:val.blockLength,
                    maxNumberOfResInBlock:val.maxNumberOfResInBlock,
                    isForMembershipDuration:val.isForMembershipDuration,
                    isForRoom:val.isForRoom,
                    guideReservationTimeID:val.guideReservationTimeID,
                })
            }
            SetResDateData(initResData);
        }

    },[resTimesData])

    const closeRoom = (roomNumber,name,customerID) => {
        CloseRoom(roomNumber,name,customerID);
    }

    const SaveResData = () => {

        console.log(resDateData);

        var regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        var error = "";
        for(let val of resDateData)
        {            
            if(val.timeFrom != "" && !regex.test(val.timeFrom))
            {
                var dayData = days.filter((item) => item.day == val.day);
                error += "Špatný formát času OD v " + dayData[0].name + ". ";  
            }

            if(val.timeTo != "" && !regex.test(val.timeTo))
            {
                var dayData = days.filter((item) => item.day == val.day);
                error += "Špatný formát času DO v " + dayData[0].name + ". ";  
            }
        }

        if(error == "")
            AddEditData({
                variables:{
                    data:resDateData
                }
            })
        else
        {
            const notify = new Notification();
            notify.setNotification(null,error,false,true,props.client);
        }
            
    }

    const SetResData = (e,day) => {

        var value = e.target.value;
        var name = e.target.name;

        var checked = false;
        var newArr = [...resDateData];

        for(let i in newArr)
        {
            if(newArr[i].day == day)
            {
                checked = true;
                newArr[i] = {...newArr[i],[name]:value}
            }
        }

        if(!checked)
            newArr.push({
                day:day,
                [name]:value,
                blockLength:30,
                maxNumberOfResInBlock:4,
                isForMembershipDuration:0,
                isForRoom:1,
                guideReservationTimeID:0
            });

        SetResDateData(newArr);
    }

    return(
        <div className="whole-container">

            <div className="row">
                <div className="col-12 col-md-6">

                    <div className="card main">
                                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t('DatesForReservationsToRooms')}
                            <button 
                                onClick = {() => SaveResData()}
                                className="btn btn-primary btn-thiner ml-auto"
                            >Uložit</button>
                        </div>
                        <div className="card-body">

                            {loading ?
                                <Loading />
                            :
                                <>
                                    {resDateError ? 
                                        <Error text = {resDateError} />
                                    :null}

                                    <div className="table-responsive">

                                        <table className="table">

                                            <thead>
                                                <tr>
                                                    <th>{translate.t("Day")}</th>
                                                    <th className="text-center">{translate.t("TimeFrom")} (hh:mm)</th>
                                                    <th className="text-center">{translate.t("TimeTo")} (hh:mm)</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {days.map((item,index) => {

                                                    var data = null;
                                                    for(let val of resDateData)
                                                    {
                                                        if(val.day == item.day)
                                                            data = val;
                                                    }

                                                    return(
                                                        <tr key = {item.day}>
                                                            <td>{item.name}</td>
                                                            <td className="text-center">
                                                                <input 
                                                                    onChange = {(e) => SetResData(e,item.day)}
                                                                    value = {data ? data.timeFrom : ""}
                                                                    type="text" 
                                                                    name="timeFrom" 
                                                                    className="form-control text-center" 
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <input 
                                                                    onChange = {(e) => SetResData(e,item.day)}
                                                                    value = {data ? data.timeTo : ""}
                                                                    type="text" 
                                                                    name="timeTo" 
                                                                    className="form-control text-center" 
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">

                    <div className="card main">
                                    
                        <div className="card-header d-flex align-items-center">
                            {translate.t('Rooms')}
                        </div>
                        <div className="card-body">

                            {data && allSocketRooms && allSocketRooms.length > 0 ?

                                <table className="table">

                                    <thead>
                                        <tr>
                                            <th>{translate.t("NameOfRoom")}</th>
                                            <th className="text-right">{translate.t("State")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {allSocketRooms.map((item,index) => (

                                            <tr key={index}>
                                                <td>{item.name}</td> 
                                                <td className="text-right">
                                                    {item.isOpen ? 
                                                        <button onClick={() => closeRoom(item.hash,item.name,data.customerContext.customerID)} className="btn btn-primary btn-thiner">Zavřít místnost</button>
                                                    : 
                                                        "Zavřeno"
                                                    }
                                                </td>
                                            </tr>

                                        ))}

                                    </tbody>
                                </table>
                            
                            :
                                <NoItems text={"Místnosti nenalezeny"} />
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default withApollo(RoomsSettings);