import React from 'react';

const Error = ({text,className}) => {
	
    return (
    	<div className={"alert alert-danger" + (className ? " " + className : "")}>	
    		{text}
    	</div>
    );
}
  
export default Error;
