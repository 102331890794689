/*
	Komponenta, kerá kontroluje, jestli je uživatel přihlášen.
*/

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query} from 'react-apollo';
import gql from 'graphql-tag';

const withAuthentication = (Component) => {
  class withAuthentication extends React.Component {
    
	render() {

		const {history} = this.props;
		const IS_LOGGED_IN = gql`
			query IsUserLoggedIn {
				isLoggedIn @client
			}
		`;

		return (

			//načítá se isLoggedIn z cache apolla
			<Query query={IS_LOGGED_IN}>
				
				{({data,loading,errro}) => {

					if(data.isLoggedIn)return(<Component {...this.props} />);
					else {
						
						localStorage.setItem("urlToShow", this.props.location.pathname);
						history.push("/"); return "";
					}

				}}

			</Query>

		);
    }
  }

  return withRouter(withAuthentication);
  
}

export default withAuthentication;