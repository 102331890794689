import React,{ Component } from 'react';
import Modal from 'react-modal';
import translate from '../Modules/Translations/index';
import close from '../Public/Images/Icons/close.webp';

Modal.setAppElement('#root');

class ModalNotification extends Component {
	  	
  render() {
	
   
    return (
    	<Modal
	        className="Modal__Bootstrap modal-dialog"
	        closeTimeoutMS={150}
	        isOpen={true}
	        onRequestClose={() => this.props.callback(false)}
	    >
	        <div className="modal-content">
	          <div className="modal-header">
	            {translate.t("Warning")}
	            <img src={close} className="close" onClick={() => this.props.callback(false)} />
	          </div>
	          <div className="modal-body">
	          
	          	{this.props.yesNo ? <p className="no-margin">{this.props.text}</p> : <div className="alert alert-danger">{this.props.text}</div>}	
	                    
	          </div>
			  {this.props.yesNo ? 
				<div className="modal-footer"> 
					{this.props.biggerNo ?
						<>
							<button type="button" className="btn btn-danger flex-fill" onClick={() => this.props.callback(false)}>{translate.t("NO")}</button>
							<button type="button" className="btn btn-primary" onClick={() => this.props.callback(true)}>{translate.t("YES")}</button>
						</>
					:
						<>
							<button type="button" className="btn btn-primary flex-fill" onClick={() => this.props.callback(true)}>{translate.t("YES")}</button>
							<button type="button" className="btn btn-danger" onClick={() => this.props.callback(false)}>{translate.t("NO")}</button>
						</>
					}
				</div>
			  :
				<div className="modal-footer"> 
					<button type="button" className="btn btn-danger" onClick={() => this.props.callback(false)}>{translate.t("Close")}</button>
				</div>
			  }
	        </div>
	    </Modal>
    );
  }
  
}


export default ModalNotification;
