import React, { useEffect, useState,useRef } from 'react';
import {withApollo,useMutation,useQuery } from 'react-apollo';
import translate from '../../Translations/index';
import {GET_ROOM_DATA} from '../Queries/room';
import {ADD_EVS_POINTS_FROM_ROOM}  from '../../Customer/Queries/customer';
import videoCamera from '../../../Public/Images/Icons/video-camera.webp';
import budha from '../../../Public/Images/Icons/budha.webp';
import Loading from '../../../GlobalComponents/Loading';
import RoomLib from '../Library/room';
import exit from '../../../Public/Images/Icons/logout_white.webp';
import calendar from '../../../Public/Images/Icons/calendar.webp';
import CustomerModalAllDetails from '../../Customer/Components/CustomerModalAllDetails';
import logo from '../../../Public/Images/logo.png';
import moment from 'moment';
import NoAccess from '../../../GlobalComponents/NoAccess';
import Notification from '../../../Library/notification';
import { UseSocket } from '../../Socket/Components/Socket';
import { STREAM_URL } from '../../../Config';
import ReservationTimes from './ReservationTimes';
import RoomReservationList from './RoomReservationList';
import { useLocation } from 'react-router-dom';

const Room = (props) => {

    let location = useLocation();
    const hash   = props.match.params.hash;

    var roomLib  = new RoomLib(props);
    var roomName = roomLib.getRoomName(hash);
    
    const {data,loading,error} = useQuery(GET_ROOM_DATA,{
        fetchPolicy: 'network-only',
        variables:{
            lang:"",
        }
    });

    const [addEvsPOintsFromRoom,{data:AddData,loading:addLoading,error:addError}] = useMutation(ADD_EVS_POINTS_FROM_ROOM,{

        onCompleted:(data)=>{

            if(data.addEvsPointsFromRoom == ""){
                let notify = new Notification();
                notify.setNotification(null,translate.t("EVSPOintsAddedForConferention"),true,false,props.client);
            }
        }   
    });

    const {socket:mainSocket,socketEstablished} = UseSocket();

    const [users,setUsers]                                = useState([]);
    const [waitingUsers,setWaitingUsers]                  = useState([]);
    const [showUserList,toogleShowUserList]               = useState(false);
    const [showReservationList,ToogleShowReservationList] = useState(false);
    const [selectedCustomer,setSelectedCustomer]          = useState(0);
    const [showReservationTimes,SetShowReservationTimes]  = useState(false);

    const [roomControllData, setRoomControllData] = useState({
        api:null,
        socket: null,
        openRoom:false,
        isModerator:false,
        firstInitSocket:false,
        hasApprovalToRoom:false,
        showReloadButton:false,
        showKnockButton:true,
        timeInterval:null,
        time:0,
        resTimesData:null,
        //allGuideReservations:null,
        guideID:null
    })

    const getStrTime = (totalSeconds) => {

        var hours   = parseInt(totalSeconds / 3600);
        var minutes = parseInt((totalSeconds % 3600) / 60);
        var seconds = (totalSeconds % 3600) % 60;

        if(hours < 10) hours = "0"+hours;
        if(minutes < 10) minutes = "0"+minutes;
        if(seconds < 10) seconds = "0"+seconds;

        return hours + ":" + minutes + ":" + seconds;

    }

    useEffect(() =>{

        const {api,openRoom,socket,isModerator,firstInitSocket,hasApprovalToRoom,showReloadButton} = roomControllData;

        if(!socket && data)
        {
            if(!showReloadButton && ((data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment()) || data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 7 || data.customerContext.customerTypeID == 5  || (data.customerContext.customerTypeID == 6 && hash == "bbf3d1e8866defe9b5b63c7fd2de6e88") || (data.settings.openAula == 1 && hash == "bbf3d1e8866defe9b5b63c7fd2de6e88") )){
 
                roomLib.setConnection(mainSocket,data.customerContext,hash,setRoomControllData,SetShowReservationTimes);
                setRoomControllData({...roomControllData,socket:mainSocket});
            }

        }

        if(socket && !firstInitSocket){

            roomLib.isRoomOpened(socket,hash);
            roomLib.receiveIsRoomOpened(socket,hash,data.customerContext,setRoomControllData,SetShowReservationTimes);
            roomLib.receiveAllGuideReservations(socket,setRoomControllData);

            if(data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5 || data.customerContext.customerTypeID == 7){
                roomLib.receiveAllUsers(socket,setUsers,setWaitingUsers);
                roomLib.receiveNewApprovalNotification(socket);
            }

            roomLib.receiveApproveEnter(socket,(approved) => {

                var timeInterval = setInterval(() => {
                    
                    setRoomControllData(prevValue => {

                        var time = 300;
                        if(process && process.env && process.env.NODE_ENV === "development")
                            time = 20;

                        if(prevValue.time == time)
                            addEvsPOintsFromRoom();
                        
                        var newValue = {...prevValue};
                        newValue.time = prevValue.time + 1;
        
                        return newValue;
                    })
                },1000);

                setRoomControllData(prevValue => {
                    var newValue = {...prevValue};
                    newValue.hasApprovalToRoom = approved;
                    newValue.timeInterval = timeInterval;

                    return newValue;
                })
            });
        }

        if(!api && data && openRoom && socket && (hasApprovalToRoom || isModerator)){

            var toolbarButtons = [
                'camera',
                'chat',
                'closedcaptions',
                'desktop',
                'download',
                //'embedmeeting',
                'etherpad',
                'feedback',
                'filmstrip',
                'fullscreen',
                'help',
                'highlight',
                //'invite',
                'linktosalesforce',
                'livestreaming',
                'microphone',
                //'noisesuppression',
                //'participants-pane',
                //'profile',
                'raisehand',
                'recording',
                //'security',
                //'select-background',
                'settings',
                'shareaudio',
                'sharedvideo',
                'shortcuts',
                'stats',
                'tileview',
                'toggle-camera',
                'videoquality'
            ];

            if(data.customerContext.customerTypeID != 2 && data.customerContext.customerTypeID != 5){
                toolbarButtons = [
                    'microphone', 'camera','desktop', 'fullscreen',
                    'fodeviceselection', 'chat','raisehand',
                    'videoquality', 'filmstrip', 'shortcuts','stats',
                    'tileview', 'download', 'closedcaptions','highlight'
                ];
            }
            else if(data.customerContext.customerTypeID == 2)
            {
                toolbarButtons = [
                    'microphone', 'camera','desktop', 'fullscreen',
                    'fodeviceselection', 'chat','raisehand',
                    'livestreaming', 'etherpad', 'sharedvideo','shareaudio',
                    'videoquality', 'filmstrip', 'shortcuts','stats',
                    'tileview', 'download', 'closedcaptions', 'mute-everyone',
                    'whiteboard','highlight','help'
                ];
            }

            var urlStr = STREAM_URL;

            var apiJItsi = new window.JitsiMeetExternalAPI(urlStr, {
                roomName:hash,
                parentNode: document.querySelector('#room-video'),
                lang:"cs",
                userInfo: {
                    displayName: (data.customerContext.astralName ? data.customerContext.astralName : (data.customerContext.roomName != "" ? data.customerContext.roomName : data.customerContext.name + " " + data.customerContext.surname))
                },
                configOverwrite:{
                    prejoinPageEnabled: false,
                    doNotStoreRoom: true,
                    disableDeepLinking: true,
                    hideConferenceSubject:true,
                    defaultLanguage: 'cs',
                    deeplinking:{
                        disabled:true
                    },
                    startWithAudioMuted: true,
                    toolbarButtons:toolbarButtons,
                    toolbarConfig: {
                        // The initial number of milliseconds for the toolbar buttons to be visible on screen.
                        initialTimeout: 10000,
                        // Number of milliseconds for the toolbar buttons to be visible on screen.
                        timeout: 4000,
                        // Whether toolbar should be always visible or should hide after x milliseconds.
                        alwaysVisible: false,
                        // Indicates whether the toolbar should still autohide when chat is open
                        autoHideWhileChatIsOpen: false,
                    },
                    disabledNotifications: [
                        
                        'notify.invitedOneMember',
                        'notify.invitedThreePlusMembers',
                        'notify.invitedTwoMembers',
                        'notify.kickParticipant'
                    ],
                    disabledSounds:[
                        'PARTICIPANT_JOINED_SOUND',
                        'PARTICIPANT_LEFT_SOUND'
                    ],
                    hideConferenceTimer:true,
                    hideParticipantsStats: true
                },
                interfaceConfigOverwrite: { 
                    DEFAULT_BACKGROUND:"#222222",
                    DEFAULT_LOCAL_DISPLAY_NAME: 'já',
                    HIDE_INVITE_MORE_HEADER: true,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    TOOLBAR_ALWAYS_VISIBLE: true,
                    MOBILE_APP_PROMO: false,
                    TOOLBAR_BUTTONS: toolbarButtons
                },
            });

            apiJItsi.executeCommand('subject', ' ');

            roomLib.SetApiListeners(apiJItsi,setRoomControllData);

            setRoomControllData((prevValue) => {
                return {...prevValue,api:apiJItsi}
            });

        }

        
    },[data,roomControllData])


    useEffect(() => {
        
        return () => {

            if(mainSocket)
            {
                mainSocket.emit("disconnect-user","z UseEffect po socketEstablished");
                roomLib.offEvents(mainSocket);
            }
        }

    },[socketEstablished]);

    useEffect(() => {

        setRoomControllData((prevValue) => {

            var newValue = {...prevValue};

            if(newValue.socket){

                newValue.socket.emit("disconnect-user","z UseEffect po změně HASH");

                roomLib.offEvents(newValue.socket);
                
                newValue.socket         = null;
                newValue.firstInitSocket = false;
                newValue.openRoom         = false;
                newValue.showReloadButton = false;
                
            }else{
               
                newValue.showReloadButton = false;
                
            }

            return newValue;

        }) 
        
    },[hash]);
            
    return(
        <div id="room" className={"whole-container d-flex flex-column justify-content-center align-items-center"}>	

            {loading ?
                <Loading />
            :
                ((data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment()) || data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5 || data.customerContext.customerTypeID == 7 || (data.customerContext.customerTypeID != 6 && data.customerContext.membershipTo && hash == "bbf3d1e8866defe9b5b63c7fd2de6e88")  || (data.settings.openAula == 1 && data.customerContext.customerTypeID == 6 && hash == "bbf3d1e8866defe9b5b63c7fd2de6e88") ?
                    (!roomControllData.showReloadButton ? 
                        
                        (roomControllData.openRoom ?
                        
                            (roomControllData.hasApprovalToRoom || roomControllData.isModerator ?

                                <div id="room-content">
                                    <div id="room-header">
                                        <div className="logo">
                                            <img alt="Vládce Síly" src={logo} />
                                        </div>
                                        <div className="dot tl"></div>
                                        <div className="dot tr"></div>
                                    </div>
                                    <div id="room-video"></div>
                                    <div id="room-footer">
                                        
                                        {waitingUsers.length > 0 || users.length > 0 || roomControllData.resTimesData && roomControllData.resTimesData.GuideReservationDayDataForRoom.length > 0 ?
                                            <button className="users" onClick={() => {toogleShowUserList(false); ToogleShowReservationList(!showReservationList)}}>
                                                <img src={calendar} /> 
                                                {translate.t("ReservationList")} 
                                                {roomControllData.isModerator ?
                                                    <>
                                                        <span className="green">{users.length - 1}</span> / <span className="red">{waitingUsers.length}</span>
                                                    </>
                                                :null}
                                            </button>
                                        :null}

                                        {showReservationList  ?
                                            <RoomReservationList
                                                waitingUsers = {waitingUsers}
                                                users = {users}
                                                roomNumber = {hash}
                                                isModerator = {roomControllData.isModerator}
                                                allGuideReservations = {roomControllData.allGuideReservations}
                                                setSelectedCustomer = {(customerID) => setSelectedCustomer(customerID)}
                                                reservationTimesData = {roomControllData.resTimesData}
                                                OnApproveEnter = {(e,customerID) => {e.preventDefault();roomLib.approveEnter(setRoomControllData,customerID)}}
                                                OnRemoveUser = {(customerID) => roomLib.removeUser(setRoomControllData,customerID)}
                                            />
                                        :null}
                                        
                                        <div className="ml-auto d-flex">
                                            {(!roomControllData.isModerator ? <div className="visit-time">{getStrTime(roomControllData.time)}</div> : null)}
                                            <button onClick={() => {roomLib.disconnectVideo(roomControllData.isModerator,setRoomControllData,null," ON button click")}} className="btn-danger last">{translate.t("LeaveRoom")} <img className="ml-2" src={exit} /></button>
                                        </div>                        
                                    </div>
                                </div>

                            :
                                (roomControllData.showKnockButton ?

                                    <>
                                        <img src={videoCamera} className="mb-5 room-icon" />
                                        <button style={{minWidth: "245px",marginBottom:'10px'}} onClick={() => roomLib.doKnock(hash,data.customerContext,setRoomControllData,SetShowReservationTimes)} className="btn btn-primary btn-large">{translate.t("Knock")} <br /></button>
                                    </>

                                :
                                    <div className="waiting-info">
                                        <img src={budha} className="room-icon" />
                                        <p>{translate.t("WaitUntilYourAreApproved")}</p>
                                    </div>
                                )

                            )
                            
                        :
                            (data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5 || data.customerContext.customerTypeID == 7 ?
                                <>
                                    <img src={videoCamera} className="mb-5 room-icon" />
                                    <button style={{minWidth: "245px",marginBottom:'10px'}} onClick={() => roomLib.setOpenRoom(setRoomControllData,hash,true,data.customerContext.customerID)} className="btn btn-primary btn-large">{translate.t("OpenRoom")} <br /></button>
                                    <p className="room-name">{roomName}</p>
                                </>
                            :
                                <div className="waiting-info">
                                    <img src={budha} className="room-icon" />
                                    <p>{translate.t("WaitUntilRoomIsOpen")}</p>
                                </div>
                            )
                        )


                    :
                        <>
                            <p>{translate.t("RoomIsClosedByModerator")}</p>
                            <button style={{minWidth: "245px",marginBottom:'10px'}} onClick={() => window.location.reload() } className="btn btn-primary btn-large">{translate.t("BackToRoom")} <br /></button>
                        </>         
                    )
                    :
                    <NoAccess customer = {data.customerContext} showFooter = {true} title={(roomName == "Aula Astrální akademie" ? translate.t("HallOfVS") : translate.t("VSHall"))} text={(roomName == "Aula Astrální akademie" ? translate.t("RoomAulaNoAccessInfo") : translate.t("RoomNoAccessInfo"))} />
                )
            }

            {data && showReservationTimes && roomControllData.resTimesData ?
                <ReservationTimes 
                    client = {props.client}
                    roomNumber = {hash}
                    reservationTimesData = {roomControllData.resTimesData}
                    customerID = {data.customerContext.customerID}
                    guideID = {roomControllData.guideID}
                    OnClose = {() => SetShowReservationTimes(false)} 
                    OnComplete = {() => roomLib.JoinRoom(hash,data.customerContext,setRoomControllData,SetShowReservationTimes)}
                />
            :null}

            {selectedCustomer != 0 ?
                <CustomerModalAllDetails customerID = {selectedCustomer} saveModal={() => {setSelectedCustomer(0)}} closeModal = {() => {setSelectedCustomer(0)}} />
            :null}      
              
        </div>
    );
    
}

export default withApollo(Room);