import React,{ Component } from 'react';

const INITIAL_STATE = {
    value:""
}

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class SearchInput extends Component {

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.changeText    = this.changeText.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.triggerChange = this.triggerChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentWillMount() {
        this.timer = null;
        if(this.props.value){
            this.setState({value:this.props.value});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
          this.setState({ value: nextProps.value });
        }
    }

    changeText(e){

        clearTimeout(this.timer);

        this.setState({value:e.target.value})
        this.timer = setTimeout(this.triggerChange,WAIT_INTERVAL);
    }

    triggerChange(){

        this.props.onChange(this.state.value);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(this.timer);
            this.triggerChange();
        }
    }
	
    render() {

        const {className,placeholder} = this.props;
        return (
            <input 
                type        = "text" 
                className   = {className} 
                placeholder = {placeholder} 
                value       = {this.state.value} 
                onChange    = {(e) => this.changeText(e)}
                onKeyDown   = {(e) => this.handleKeyDown(e)}
            />
        );
    }
  
}


export default SearchInput;
