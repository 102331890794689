import gql from 'graphql-tag';

export const ADD_EDIT_GROUP = gql`
    mutation AddEditGroup(
        $groupID:ID,
        $customerID:ID,
        $name:String
    ){
        addEditGroup(
            groupID:    $groupID,
            customerID: $customerID,
            name:       $name
        ){
            customerID
            groupID
            creator
            group{
                name
            }
        }
    }
`;

export const GET_GROUP = gql`
    query GetGroupData($groupID:ID){
        group(groupID:$groupID){
            groupID
            name   
        }
    }
`;

export const DELETE_GROUP = gql`
    mutation DeleteGroup($groupID:ID){
        deleteGroup(groupID:$groupID)
    }
`;

export const JOIN_GROUP = gql`
    mutation JoinGroup($groupCustomerID:ID,$groupID:ID){
        joinGroup(groupCustomerID:$groupCustomerID,groupID:$groupID)
    }
`;

export const GET_GROUP_SEARCH_DATA = gql`
    query GetGroupMembersData($offset:Int,$limit:Int,$text:String){
        allCustomersWithActiveMembership(offset:$offset,limit:$limit,text:$text){
            customerID
            astralName
            name
            photo
        }
    }
`;

export const GET_GROUP_MEMBERS_DATA = gql`
query GetGroupMembersData($groupID:ID){
    allGroupCustomers(groupID:$groupID){
        groupCustomerID
        groupID
        customerID
        creator
        acceptInvitation
        customer{
            name
            astralName
            photo
        }
    }
}
`;

export const ADD_GROUP_CUSTOMER = gql`
    mutation AddGroupCustomer($groupID:ID,$customerID:ID,$creator:Int,$acceptInvitation:Int){
        addGroupCustomer(groupID:$groupID,customerID:$customerID,creator:$creator,acceptInvitation:$acceptInvitation){
            groupCustomerID
            groupID
            customerID
            creator
            acceptInvitation
        }
    }
`;

export const REMOVE_CUSTOMER_FROM_GROUP = gql`
mutation RemoveCustomerFromGroup($groupCustomerID:ID){
    removeCustomerFromGroup(groupCustomerID:$groupCustomerID)
}
`;





