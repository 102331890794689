import {WEBSOCKET_SERVER} from '../../../Config/index';
import io from "socket.io-client";
import { useState,createContext,useContext,useEffect } from 'react';
import Notification from '../../../Library/notification';
import { useApolloClient } from "react-apollo";

const socketContext = createContext();

export const SocketProvider = ({children}) => {

    const socket = UseProviderSocket();

    return(
        <socketContext.Provider value={socket}>
            {children}
        </socketContext.Provider>
    )
}

export const UseSocket = () => {
    return useContext(socketContext);
}

const UseProviderSocket = () => {

    const notify = new Notification();

    const client = useApolloClient();

    const [receiveChatMessage, SetReceiveChatMessage] = useState(null);
    const [receiveChatMessageInVSlist, SetReceiveChatMessageInVSlist] = useState(null);
    const [receiveChatMessageInChat, SetReceiveChatMessageInChat] = useState(null);
    const [receiveDeletedChatMessage, SetReceiveDeletedChatMessage] = useState(null);
    const [receiveDeletedChatAllMessages, SetReceiveDeletedChatAllMessages] = useState(null);
    const [receiveNewNotifications, SetReceiveNewNotifications] = useState(null);
    const [allRooms, SetAllRooms] = useState(null);
    const [socket, SetSocket] = useState(null);
    const [socketEstablished, SetSocketEstablished] = useState(false);
    const [internetOnline,SetInternetOnline ] = useState(true);

    const OnOnline = () => {
        notify.setNotification(null,"Jste opět nejlepší kámoši s internetem.",true,false,client);
        SetInternetOnline(true);
    }

    const OnOffline = () => {
        notify.setNotification(null,"Ajaj, internet nechce být váš kámoš, odešel.",false,true,client);
        SetInternetOnline(false);
    }

    useEffect(() => {

        window.addEventListener('online', OnOnline);
        window.addEventListener('offline', OnOffline);

    },[]);

    const SetConnection = () => {

        var s = io.connect(WEBSOCKET_SERVER);

        s.on("receive-chat-message",(data) => {
            SetReceiveChatMessage(data);
        });

        s.on("receive-chat-message-in-vs-list",(data) => {
            SetReceiveChatMessageInVSlist(data);
        });

        s.on("receive-chat-message-in-chat",(data) => {
            SetReceiveChatMessageInChat(data);
            setTimeout(() => {
                SetReceiveChatMessageInChat(null);
            },100)
        });

        s.on("receive-deleted-chat-message",(data) => {
            SetReceiveDeletedChatMessage(data);
        });

        s.on("receive-deleted-chat-all-messages",(data) => {
            SetReceiveDeletedChatAllMessages(data);
        });

        s.on("get-all-rooms",(data) => {
            SetAllRooms(data);
        });

        s.on("new-notification",(data) => {
            SetReceiveNewNotifications(data);
        });

        s.on('disconnect', () => {	
            GetAllRooms();
        })

        SetSocket(s);
        SetSocketEstablished(true);
    }

    const SendChatMessage = (data) => {
        socket.emit('send-chat-message', data);
    }

    const SendDeleteChatMessage = (data) => {
        socket.emit('send-delete-chat-message', data);
    }

    const SendDeleteChatAllMessages = (data) => {
        socket.emit('send-delete-chat-all-messages', data);
    }

    const SendGuideReservation = (data) => {
        socket.emit('send-guide-reservation', data);
    }

    const ConfirmGuideReservation = (data) => {
        socket.emit('confirm-guide-reservation', data);
    }

    const MoveGuideReservation = (data) => {
        socket.emit('move-guide-reservation', data);
    }


    const GetAllRooms = () => {
        SetSocket((prevValue) => {
            prevValue.emit('get-all-rooms');
            return prevValue;
        })
        
    }

    const CloseRoom = (roomNumber,roomName,customerID) => {
        socket.emit("close-room",{
            selectedRoom:roomNumber,
            roomName,
            customerID
        });
    }
    
    return({
        receiveChatMessage,
        receiveChatMessageInVSlist,
        receiveChatMessageInChat,
        receiveDeletedChatMessage,
        receiveDeletedChatAllMessages,
        receiveNewNotifications,
        allRooms,
        socket,
        internetOnline,
        socketEstablished,
        SetConnection,
        SendChatMessage,
        SendDeleteChatMessage,
        SendDeleteChatAllMessages,
        GetAllRooms,
        CloseRoom,
        SendGuideReservation,
        ConfirmGuideReservation,
        MoveGuideReservation
    })

}
