import React,{ Component } from 'react';
import translate from '../Modules/Translations/index';

const Loading = ({color}) => {
	
    return (
    	<div className="whole-loading">	
        <div className="loading"><div className="spinner"><div className="rect1"></div><div className="rect2"></div><div className="rect3"></div><div className="rect4"></div><div className="rect5"></div></div><div className={"text" + (color ? " " + color : "")}>{translate.t("Loading")}...</div></div>
    	</div>
    );
  
}


export default Loading;
