import Modal from 'react-modal';
import close from '../../../Public/Images/Icons/close.webp';

const ModalNotification = ({SelectNotification,notification}) => {
    return(
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => SelectNotification(null)}
        >
            <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                    {notification.title}
                    <img src={close} className="close" onClick={() => SelectNotification(null)} />
                </div>
                <div className="modal-body">
                    <div className="c-notifications">
                        <div 
                            className="c-notification" 
                            dangerouslySetInnerHTML={{__html:notification.text}}
                        ></div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalNotification;