import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    notifications: [Notification]
  }

  type Notification {
    error: Boolean
    success: Boolean
    text: String!
  }

`;

export const GET_NOTIFICATIONS = gql`
	{
		notifications @client{
			text
			success
			error
		}
	}
`;

export const resolvers = {
    
    
  };