import React from 'react';
import translate from './index';
import Loading from '../../GlobalComponents/Loading';
import {GET_DOMAIN_DATA_BY_DOMAIN} from '../../GlobalQueries/globalQueries';
import {GET_CUSTOMER_DETAILS} from '../Customer/Queries/customer';

const withTranslation = (Component,client) => {
	class withTranslation extends Component {
		
			constructor(props){
				super(props);	
				
				this.state = {
					error:'',
					loading:true		  
				}
			}
		
			componentDidMount() {				
				this.setLang();	
			}

			setLang = async () => {
				try {

					var d = window.location.hostname;
					d = d.split(".");
					if(d.length > 1){
						d.shift();
						d = d.join(".");
					}else{
						d = d[0];
					}
					
					var domainData = await client.query({
						query:GET_DOMAIN_DATA_BY_DOMAIN,
						variables:{
							domain: d
						}
					});

					translate.locale = domainData.data.domainDataByDomain.lang;
					
					/*
					const hostname = window.location.hostname;
					if(hostname == "astralnicestovani.cz" || hostname == "www.astralnicestovani.cz" || hostname == "localhost"){
						translate.locale = 'cz';
					}else{
						translate.locale = 'en';
					}*/

					//i18nConfig.locale = (intlValue == "cz" ? "cs" : translate.locale);
					this.setState({loading:false});
					
				} catch(error) {
					// error reading value
					//this.setState({loading:false});
					alert('Error: ' + error);
				}
			}
			
			render() {
				if(this.state.loading){
					return (
						<Loading />
					);
				}else{
					return (	
						<Component {...this.props} />
					);
				}
					
			}
	}


	return withTranslation;
  
}

export default withTranslation;