/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_AFFILIATE_DATA = gql`
    query GetAffiliateData {
        customerContext{
            countryID
            domainID
            regCode
            membershipTo
            customerTypeID
            customerID
            bankAccount
            swift
            iban
            lastCustomerPayment{
                status
            }
            domain{
                domainName
                currencyTitle
                posID
            }
            rules{
                showForum
                showAffiliate
                showDaily
                showMaterial
                showMyVS
                showRooms
                showRoomsWithNoMembership
                showPreparing
                showMembershipButton
                showEventsCalendar
                showPageAfterReg
                showVsList
                showSettingsRooms
                showEliteVS
                showIntensively
                showObserver
            }
            lastCompletedCustomerPayment{
				amount
				orderID
                dateFrom
                dateTo
			}
        }
        allParentAffiliateCustomers{
            customerID
            dateDeleted
            dateAdd
            customerAffiliate
            customer{
                name
                surname
                astralName
                membershipTo
            }
            allCustomerPaymentsInAffiliate(status:"COMPLETED"){
                commisionInAffiliate
                commisionInAffiliateFrom
                affiliatePaidOff
                amount
                dateFromString
                dateToString
            }
        }
        allParentAffiliateGiftCustomers{
            customerID
            customer{
                name
                surname
                astralName
                membershipTo
            }
        }
        allAffiliatePaidOffs{
            customerAffiliatePaidOff
            date
            value
            hash
        }
        allCustomerAffiliateUrl{
            customerAffiliateUrlID
            affiliateID
            url
        }
        totalValueToPaidOff{
            value
        }
    }
`;

export const GET_AFFILIATE_PROFITS = gql`
    query GetAffiliateProfits($month:Int,$year:Int) {
        
        affiliateProfits(month:$month,year:$year){
            customerID
            customerAffiliate
            month
            year
            dateAdd
            dateDeleted
            customer{
                name
                surname
            }
            customerPaymentsGroup(useDateFrom:false){
                customerID
                value 
                affiliateProfit
                count
            }
            
        }
    }
`;

export const DO_PAID_OFF = gql`

    mutation DoPaidOff {
        doPaidOff
    }
`;

export const ADD_AFFILIATE_URL = gql`

    mutation AddAffiliateUrl($affiliateID:ID,$url:String) {
        addAffiliateUrl(affiliateID:$affiliateID,url:$url){
            customerAffiliateUrlID
            affiliateID
            url
            error 
        }
    }
`;
