import translate from '../../Translations/index';
import moment from 'moment';

const FilterDailyWarning = ({
    filterData,
    CancelFilter,
}) => {
    return(
        <>
            {filterData.dateFrom && filterData.dateTo ?
                <div className="filter-warning-panel">
                    <p className="no-margin">
                    {translate.t("ActiveFilter")}: <strong>{moment(filterData.dateFrom).format("D.M.YYYY")} - {moment(filterData.dateTo).format("D.M.YYYY")}</strong>
                    </p>
                    <button 
                        onClick={() => CancelFilter()}
                        className="btn btn-danger btn-thiner ml-auto">{translate.t("Cancel")}</button>
                </div>
            :null}
        </>
    )
}

export default FilterDailyWarning;