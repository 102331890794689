import React,{useEffect, useRef, useState} from 'react';
import {useQuery,useMutation,withApollo} from 'react-apollo';
import {GET_VS_LIST_DATA,GET_CUSTOMER_DETAILS,GET_CUSTOMER_BY_REG_CODE} from '../Queries/customer';
import {DELETE_GROUP,JOIN_GROUP} from '../Queries/group';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import ScrollArea from 'react-scrollbar';
import NoAccess from '../../../GlobalComponents/NoAccess';
import moment from 'moment';
import Profile from './Profile';
import Chat from './Chat';
import AllCustomerDailyPosts from '../../Daily/Components/AllCustomerDailyPosts';
import SearchInput from '../../../GlobalComponents/SearchInput';
import user from '../../../Public/Images/Icons/user_small.webp';
import messages from '../../../Public/Images/Icons/messages.webp';
import diary from '../../../Public/Images/Icons/diary.webp';
import stars from '../../../Public/Images/Icons/stars.webp';
import privateGuidense from '../../../Public/Images/Icons/private_guided.webp';
import {SERVER_URL} from '../../../Config/index';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';
import AddEditGroup from './AddEditGroup';
import GroupMembers from './GroupMembers';
import edit from '../../../Public/Images/Icons/edit.webp';
import remove from '../../../Public/Images/Icons/remove.webp';
import messagesIcon from '../../../Public/Images/Icons/messages_big.webp';
import back from '../../../Public/Images/Icons/back.webp';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import EVSPoints from './EVSPoints';
import { UseSocket } from '../../Socket/Components/Socket';
import {useLongPress} from '../../../Library/hooks';
import Helper from '../../../Library/helper';
import SwitchInput from '../../../GlobalComponents/SwitchInput';

var vsListScrollRef;

const VSList = (props) => {

    var helper = new Helper();

    const {receiveChatMessageInVSlist} = UseSocket();
   
    const [regCode,setRegCode] = useState("");
    const [openSidebar,setOpenSidebar] = useState(false);
    const [addGroup,showAddGroup] = useState(false);
    const [deleteGroupID,setDeleteGroupID] = useState(null);
    const [groupMemebers,showGroupMemebers] = useState(null);
    const [selectedGroup,setSelectedGroup] = useState(null);
    const [acceptInvitation,setAcceptInvitation] = useState(false);
    const [openGroup,setOpenGroup] = useState(null);
    const [showMessagesOptions,SetShowMessagesOptions] = useState(false);
    const [variables,setVariables] = useState({
        text: "",
        offset:0,
        limit:50,
        onlyGuides:false,
        withoutWeekFreeCustomers:true
    });
    const [selectedTab,setTab] = useState(2);

    const longPressPros = useLongPress((e) => {
        if(helper.IsiOS())
            OpenMessageSettings(e,false);
    },500);

    const {data,loading,error} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const {data:vsListData,loading:vsListLoading,error:vsListError,fetchMore,refetch} = useQuery(GET_VS_LIST_DATA,{
        fetchPolicy: 'network-only',
        variables
    });

    const {data:custData,loading:custLoading,error:custError} = useQuery(GET_CUSTOMER_BY_REG_CODE,{
        fetchPolicy: 'network-only',
        variables:{
            regCode
        }
    });

    const [deleteGroup,{error:errorDelete,loading:loadingError}] = useMutation(DELETE_GROUP,{
        onCompleted:(backData) =>{
            
            const {allCustomersWithActiveMembership,customersWithActiveMembershipCount,allCustomerGroups} = props.client.readQuery({ query: GET_VS_LIST_DATA,variables});
            
            var arr = [...allCustomerGroups];
            for(let i = 0; i < arr.length; i++){
                if(arr[i].groupID == backData.deleteGroup){
                    arr.splice(i,1);
                }
            }

            props.client.writeQuery({ 
                query:GET_VS_LIST_DATA,
                variables,
                data:{
                    allCustomersWithActiveMembership,
                    customersWithActiveMembershipCount,
                    allCustomerGroups:arr
                } 
            }); 

            if(openGroup && backData.deleteGroup == openGroup.groupID){
                selectGroup(null);
            }

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyDeleted"),true,false,props.client);

        }
    });

    const [joinGroup,{error:errorJoin,loading:loadingJoin}] = useMutation(JOIN_GROUP,{
        onCompleted:(backData) =>{

            const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});
            var custContext = {...customerContext};

            custContext.notJoinedGroupCount--;
                
            props.client.writeQuery({ 
                query:GET_HEADER_DATA,
                data:{
                    customerContext:custContext
                } 
            }); 
            
            refetch();

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyJoined"),true,false,props.client);

        }
    });

    useEffect(() => {

        const regCode = props.match.params.regCode;
        const messages = props.match.params.messages;

        if(regCode){
            setRegCode(regCode);
            if(messages == "messages"){
                setTab(2);
            }else{
                setTab(1);
            }
            
        }

    },[]);

    useEffect(() => {

        if(vsListData && data && custData && receiveChatMessageInVSlist){
            
            setTab((prevValue) => {

                setOpenGroup((prevOpenGroup) =>{

                    var d = receiveChatMessageInVSlist;
                    
                    var hasGroup = false;
                    if(d.toGroupID != 0 && (!prevOpenGroup || (prevOpenGroup && prevOpenGroup.groupID != d.toGroupID))){
                        for(let i = 0; i < vsListData.allCustomerGroups.length;i++){
                            if(d.toGroupID == vsListData.allCustomerGroups[i].groupID && vsListData.allCustomerGroups[i].acceptInvitation == 1){
                                hasGroup = true;
                            }
                        }
                    }

                    if(hasGroup || ((d.toCustomerID == data.customerContext.customerID) && (prevValue != 2 || !regCode || (custData.customerByRegCode && prevValue == 2 && d.fromCustomerID != custData.customerByRegCode.customerID))))
                    {
                        const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});
                        var custContext = {...customerContext};
    
                        custContext.totalNotReadChatMessages++;
                            
                        props.client.writeQuery({ 
                            query:GET_HEADER_DATA,
                            data:{
                                customerContext:custContext
                            } 
                        }); 

                        refetch();
                    }

                    return prevOpenGroup;

                });

                return prevValue;

            }) 
            
        }

    },[receiveChatMessageInVSlist])

    useEffect(() => {
        if(
            data && !variables.onlyGuides &&
            (
                data.customerContext.lastCompletedCustomerPayment && data.customerContext.lastCompletedCustomerPayment.amount == 0 && 
                moment(data.customerContext.lastCompletedCustomerPayment.dateFrom).add(7,"d").format("YYYY-MM-DD") == moment(data.customerContext.lastCompletedCustomerPayment.dateTo).format("YYYY-MM-DD")
            )
        )
        {
            setVariables({...variables,onlyGuides:true})
        }

        if(data && (data.customerContext.customerTypeID == 2 || data.customerContext.customerTypeID == 5))
            setVariables({...variables,withoutWeekFreeCustomers:false})

    },[data])
    
    const delGroup = (act) => {

        if(act){
            deleteGroup({
                variables:{
                    groupID:deleteGroupID
                }
            });
        }

        setDeleteGroupID(null);

    }

    const joGroup = (act) => {

        if(act){
            joinGroup({
                variables:{
                    groupCustomerID:acceptInvitation.groupCustomerID,
                    groupID:acceptInvitation.groupID,
                }
            });


        }

        setAcceptInvitation(null);

    }

    const selectGroup = (item) => {
        if(item.acceptInvitation == 1)
        {
            setOpenGroup(item);
            setRegCode("");
            setOpenSidebar(false);
        }
    }

    const selectVs = (e,item) => {
        e.preventDefault();
        setOpenSidebar(false);
        setRegCode(item.regCode);
        setSelectedGroup(null);
        setOpenGroup(null);
    }

    const goBack = () => {
        setRegCode("");
        setSelectedGroup(null);
        setOpenGroup(null);
    }

    const OpenMessageSettings = (e,stopPropagation) => {

        if(stopPropagation)
        {
            e.preventDefault();
            e.stopPropagation();
        }

        SetShowMessagesOptions(true);
        
    }

    var loadingData = false;
    if(loading || custLoading)loadingData = true;

    var notInGroupCount = 0;
    if(vsListData){
        for(let i = 0; i < vsListData.allCustomerGroups.length; i++){
            if(vsListData.allCustomerGroups[i].acceptInvitation == 0)notInGroupCount++;
        }
    }

    if(custData && custData.customerByRegCode && selectedTab == 4 && (custData.customerByRegCode.customerTypeID == 2 || custData.customerByRegCode.customerTypeID == 5)){
        setTab(1);
    }

    var isWeekFree = false;
    if(
        data && data.customerContext.lastCompletedCustomerPayment && data.customerContext.lastCompletedCustomerPayment.amount == 0 && 
        moment(data.customerContext.lastCompletedCustomerPayment.dateFrom).add(7,"d").format("YYYY-MM-DD") == moment(data.customerContext.lastCompletedCustomerPayment.dateTo).format("YYYY-MM-DD")
    )
        isWeekFree = true

    var access = false;
    if(data && data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment())
        access = true;

    return(
        <div id="vs-list" className={"whole-container" + (!openGroup && !regCode ? " info" : "") +(regCode != "" ? " full-height-more" : "" ) + (!access ? " d-flex flex-column justify-content-center align-items-center flex1" : "")}>

                {access ?
                    <>                       
                        {!loadingData ?
                            
                            <div className={"vs-content" + (!openGroup && !regCode ? " info" : "")}>

                                {openGroup ?

                                    <>
                                        <div className="group-header">

                                            <img src={back} className="back" onClick={(e) => goBack()} />

                                            <div className="flex-1 d-flex flex-column">

                                                <h3 className="no-margin">{openGroup.group.name}</h3>
                                                <p className="no-margin fs-14">
                                                    <a 
                                                        onClick={(e) => {e.preventDefault();showGroupMemebers(true);}} 
                                                        href=""
                                                    >
                                                        {(openGroup.creator == 1 ? translate.t("EditMemersList") : translate.t("MemebrsList"))}
                                                    </a>
                                                </p>
                                                
                                            </div>
                                        </div>

                                        <Chat vsListScrollRef = {vsListScrollRef} refetchVsList = {refetch} vsListVariables = {variables} me = {data.customerContext} vs = {null} groupID={openGroup.groupID} />
                                    </>        

                                :

                                    (regCode ?
                                        
                                        <div className="vs-tabs">

                                            <ul className="nav nav-tabs with-border">
                                                <li onClick={(e) => goBack()}><img src={back} className="back" /></li>
                                                <li className="name">{(custData.customerByRegCode.astralName ? custData.customerByRegCode.astralName : (custData.customerByRegCode.name ? custData.customerByRegCode.name : translate.t("NewStudent")))}</li>
                                                <li className="nav-item">
                                                    <div 
                                                        onClick={(e) => {e.preventDefault();setTab(1)}} 
                                                        className={"nav-link" + (selectedTab == 1 ? " active" : "")}
                                                    >
                                                        <img src={user} />
                                                        <span>{translate.t("Profile")}</span>
                                                    </div>
                                                </li>
                                                <li className="nav-item" >
                                                    <div 
                                                        {...longPressPros}
                                                        onClick={(e) => {e.preventDefault();setTab(2)}} 
                                                        onContextMenu={(e) => {
                                                            if(!helper.IsiOS())
                                                            {
                                                                e.persist();
                                                                OpenMessageSettings(e,true);
                                                            }
                                                        }} 
                                                        className={"nav-link" + (selectedTab == 2 ? " active" : "")}
                                                    >
                                                        <img src={messages} />
                                                        <span>{translate.t("Chat")}</span>
                                                    </div>
                                                </li>
                                                {custData.customerByRegCode.customerTypeID != 5 ?
                                                <li className="nav-item">
                                                    <div 
                                                        onClick={(e) => {e.preventDefault();setTab(3)}} 
                                                        className={"nav-link" + (selectedTab == 3 ? " active" : "")}
                                                    >
                                                        <img src={diary} />
                                                        <span>{translate.t("Daily")}</span>
                                                    </div>
                                                </li>
                                                :null}
                                                {custData.customerByRegCode.customerTypeID != 2 && custData.customerByRegCode.customerTypeID != 5 ?
                                                    <li className="nav-item">
                                                        <div 
                                                            onClick={(e) => {e.preventDefault();setTab(4)}} 
                                                            className={"nav-link" + (selectedTab == 4 ? " active" : "")}
                                                        >
                                                            <img src={stars} />
                                                            <span>{translate.t("EVSPoints")}</span>
                                                        </div>
                                                    </li>
                                                :null}
                                                {custData.customerByRegCode.customerTypeID == 2 || custData.customerByRegCode.customerTypeID == 5 ?
                                                    <>
                                                        
                                                        <li className="nav-item">
                                                            <div 
                                                                onClick={(e) => {e.preventDefault();setTab(5)}} 
                                                                className={"nav-link" + (selectedTab == 5 ? " active" : "")}
                                                            >
                                                                <img src={privateGuidense} />
                                                                <span>{translate.t("IndividualGuidanse")}</span>
                                                            </div>
                                                        </li>
                                                        
                                                        <li className="btn-item">
                                                            <a 
                                                                target='_blank'
                                                                href= {
                                                                    custData.customerByRegCode.customerID == 244 && "https://mujterapeut.cz/azgarielus" ||
                                                                    custData.customerByRegCode.customerID == 153 && "https://mujterapeut.cz/ankara" ||
                                                                    custData.customerByRegCode.customerID == 237 && "https://mujterapeut.cz/kler" ||
                                                                    custData.customerByRegCode.customerID == 246 && "https://mujterapeut.cz/sidris"
                                                                } 
                                                                className="btn btn-primary"
                                                            >{translate.t("PlanPrivateMeeting")}</a>
                                                        </li>
                                                    </>
                                                :null}
                                            </ul>

                                            {selectedTab == 1 ?
                                                <div className="tiny-content">
                                                    <Profile isVSList = {true} customer = {custData.customerByRegCode} />
                                                </div>
                                            :null}

                                            {selectedTab == 2 ?
                                                <Chat showMessagesOptions={showMessagesOptions} SetShowMessagesOptions={SetShowMessagesOptions} groupID={0} vsListScrollRef = {vsListScrollRef} refetchVsList = {refetch} vsListVariables = {variables} me = {data.customerContext} vs = {custData.customerByRegCode} />
                                            :null}

                                            {selectedTab == 3 ?
                                                <AllCustomerDailyPosts 
                                                    isVSList = {true} 
                                                    materials = {custData.customerByRegCode.materials} 
                                                    lastMaterialID = {custData.customerByRegCode.customerLastMaterial.materialID} 
                                                    myMotivation = {custData.customerByRegCode.myMotivation} 
                                                    guideID = {0} 
                                                    customerID = {custData.customerByRegCode.customerID} 
                                                    customerPrivacy = {custData.customerByRegCode.customerPrivacy} 
                                                />
                                            :null}

                                            {selectedTab == 4 ?
                                                <EVSPoints 
                                                    customer = {custData.customerByRegCode} 
                                                />
                                            :null}

                                            {selectedTab == 5 ?
                                                <div className="card">
                                                    <div className="card-header">
                                                        {custData.customerByRegCode.customerTypeID == 2 ?
                                                            translate.t("PrivateGuided")
                                                        :
                                                            translate.t("PrivateSidrisGuided")
                                                        }
                                                    </div>
                                                    <div className="card-body">
                                                        {custData.customerByRegCode.customerTypeID == 2 ?
                                                            <p>{translate.t("PrivateGuidedText")}</p>
                                                        :
                                                            <p>{translate.t("PrivateSidrisGuidedText")}</p>
                                                        }
                                                    </div>
                                                </div>
                                            :null}

                                            
                                        </div>

                                        :
                                        <>
                                            {isWeekFree ?
                                                <div className="tiny-content">
                                                    <img src={messagesIcon} />                                             
                                                    <p>{translate.t("vsListMainText6")}</p>
                                                    <p>{translate.t("vsListMainText7")}</p>
                                                    <p>{translate.t("vsListMainText8")}</p>
                                                    <p>{translate.t("vsListMainText9")}</p>
                                                    <p>{translate.t("vsListMainText10")}</p>
                                                    <p>{translate.t("vsListMainText11")}</p>
                                                </div>
                                            :
                                            <div className="tiny-content">
                                                <img src={messagesIcon} />                                             
                                                <p>{translate.t("vsListMainText")}</p>
                                                <p>{translate.t("vsListMainText2")}</p>
                                                <p>{translate.t("vsListMainText3")}</p>
                                                <p>{translate.t("vsListMainText4")}</p>
                                                <p>{translate.t("vsListMainText5")}</p> 
                                            </div>
                                            }
                                        </>
                                    )
                                }
                                
                                
                            </div>
                        :null}
                        
                        <div className={"card side " + (openGroup || regCode ? "closed" : "")}>

                            {!isWeekFree ?
                                <>
                                
                                    <div className="add-group">
                                        <button onClick={() => {setSelectedGroup(0);showAddGroup(true)}} className="btn btn-primary btn-block btn-thiner">{translate.t("AddGroup")}</button>
                                    </div>
                                    <div className="groups">

                                        <ScrollArea
                                            smoothScrolling={true}
                                        >

                                            <div className="card-body vs-list group-list">
                                                {vsListData && vsListData.allCustomerGroups.map((item,index) => {
                                                    
                                                    
                                                    return (
                                                        <div className={"vs group-container" + (item.acceptInvitation == 0 ? " no-clicable" : "") + (openGroup && openGroup.groupID == item.groupID ? " selected" : "")} key={index}>                                      
                                                            <div className="group-info">

                                                                <div className="group">
                                                                    <div className="main-name" onClick={() => selectGroup(item)}>{item.group.name}</div>
                                                                    
                                                                    <div className="options">
                                                                        {item.notReadedMessages && item.notReadedMessages >= 0 ?
                                                                            <span className="badge badge-danger mr-1">{item.notReadedMessages}</span>
                                                                        :null}
                                                                        {item.creator == 1 ?
                                                                            <>
                                                                                <img src={edit} onClick={() => {setSelectedGroup(item);showAddGroup(true);}} />
                                                                                <img className="ml-3" src={remove} onClick={() => setDeleteGroupID(item.groupID)} />
                                                                            </>
                                                                        :null}
                                                                        {item.acceptInvitation == 0 ?
                                                                            <>
                                                                            <span className="badge badge-danger mr-1">{translate.t("New2")}</span>
                                                                            <button onClick={() => setAcceptInvitation(item)} className="btn btn-primary btn-thiner">{translate.t("Enter")}</button>
                                                                            </>
                                                                        :null}
                                                                    </div>
                                                                    
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                    
                                                }

                                                )}
                                                
                                            </div>

                                        </ScrollArea>

                                    </div>
                                </>
                            :null}
                            
                            <div className="search">
                                <div className="form-group">
                                    <SearchInput value={variables.text} className="form-control" placeholder={translate.t("SearchVS")} onChange={(text) => setVariables({...variables,offset:0,text:text})} />
                                </div>
                                <SwitchInput 
                                    text = {translate.t("ShowOnlyGuides")}
                                    className="smallest"
                                    OnChange = {(value) => setVariables({...variables,onlyGuides:value})}
                                />
                            </div>
                            
                            <ScrollArea
                                ref = {(ref) => {vsListScrollRef = ref}}
                                smoothScrolling={true}
                            >
                                {vsListLoading ?
                                    <Loading />
                                :
                                    <div className="card-body vs-list">

                                        {vsListData && vsListData.allCustomersWithActiveMembership.map((item,index) => (
                                            <a onClick={(e) => selectVs(e,item)} key={index} className={"vs" + (regCode == item.regCode ? " selected" : "")} >
                                                
                                                <div className="img">
                                                    <img src={(item.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + item.photo :user)} />
                                                    {item.unreadChatMessagesCount > 0 && <div className="badge badge-danger">{item.unreadChatMessagesCount}</div>}
                                                </div>
                                                <div className="info">

                                                    <p className="main-name">{(item.astralName != "" ? item.astralName : (item.name ? item.name : translate.t("NewStudent")))}</p>
                                                    {item.introduction != "" ? <p className="second-name">{item.introduction}</p> : null}
                                                    <p className="second-name">{(item.customerTypeID == 2 || item.customerTypeID == 5 ? translate.t("GuideVS") : item.customerLastMaterial.longName)}</p>

                                                </div>
                                                
                                            </a>
                                        ))}

                                        {vsListData && vsListData.customersWithActiveMembershipCount > vsListData.allCustomersWithActiveMembership.length ?

                                            <p className="text-center mt-4">
                                                <button 
                                                    onClick={async  () => await fetchMore({
                                                        variables: {
                                                            offset:vsListData.allCustomersWithActiveMembership.length
                                                        },
                                                        updateQuery: (prev, { fetchMoreResult }) => {
                                                        
                                                        if (!fetchMoreResult) return prev.allCustomersWithActiveMembership;
                                                        return Object.assign({}, prev, {
                                                            allCustomersWithActiveMembership: [...prev.allCustomersWithActiveMembership, ...fetchMoreResult.allCustomersWithActiveMembership]
                                                        });
                                                        }
                                                    })} 
                                                    className="btn btn-primary">
                                                    {translate.t("LoadMore")}
                                                </button>
                                            </p>
                                            :null
                                        }
                                        
                                    </div>
                                }
                                </ScrollArea>
                                    
                        </div>
                    </>

                :
                    (!loadingData ?
                        
                        <NoAccess customer={data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? null : data.customerContext} showFooter = {true} title={translate.t("MessagesTitle")} text={data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? translate.t("VSListNoAccessInfoFreeWeek") : translate.t("VSListNoAccessInfo")} />

                    :null)
                
                }
            
            {addGroup ?
                <AddEditGroup selectGroup={selectGroup} groupID = {selectedGroup.groupID} variables = {variables} customerID = {data.customerContext.customerID} showAddGroup={showAddGroup} /> 
            :null}

            {groupMemebers ?
                <GroupMembers creator = {openGroup.creator} groupID = {openGroup.groupID} showGroupMemebers={showGroupMemebers} />
            :null}

            {deleteGroupID ?
                <ModalNotification yesNo = {true} text={translate.t("DoYouRealyWantToDeleteGroup")} callback={delGroup} />
            :null}

            {acceptInvitation ?
                <ModalNotification yesNo = {true} text={translate.t("DoYouRealyWantToJoinThisGroup")} callback={joGroup} />
            :null}

        </div>
    )

}

export default withApollo(VSList);