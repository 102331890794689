import React,{useState} from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import close from '../../../Public/Images/Icons/close.webp';

const EventItem = ({item}) => {

    const [eventDetails, showEventDetails] = useState(null);

    return(

        <>
            <div className={"card event"}>

                <div className="card-body d-flex align-items-center" onClick={() => showEventDetails(item)}>

                    <div className="date">
                        {moment(item.eventDate).format("DD.MM.")}
                        <div className="year">{moment(item.eventDate).format("HH:mm")}</div>
                    </div>    
                    <div className="title">
                        {item.title}
                    </div>                                            
                    
                </div>

            </div>

            {eventDetails ?

                <Modal
                    className="Modal__Bootstrap modal-dialog modal-lg"
                    closeTimeoutMS={150}
                    isOpen={true}
                    onRequestClose={() => showEventDetails(null)}
                >
                    <div className="modal-content">
                        <div className="modal-header d-flex align-items-center">
                            {moment(eventDetails.eventDate).format("DD.MM.YYYY HH:mm") + " - " + eventDetails.title}
                            <img src={close} className="close" onClick={() => showEventDetails(null)} />
                        </div>
                        <div className="modal-body event">

                            <div className="tiny-content" dangerouslySetInnerHTML={{__html:eventDetails.text}}></div>

                        </div>
                    </div>
                </Modal>
                
            :null}
        </>
    )

}

export default EventItem;