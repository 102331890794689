/*
    Formulář pro přihlášení se do adminu
*/
import React,{ Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../../../Public/Images/logo.svg';
import emailIcon from '../../../Public/Images/Icons/email_24.webp';
import emailIconA from '../../../Public/Images/AnimatedIcons/email.webp';
import key from '../../../Public/Images/Icons/key.webp';
import keyA from '../../../Public/Images/AnimatedIcons/key.webp';
import Login from '../Queries/Login';
import translate from '../../Translations/index';
import { withApollo,Query } from 'react-apollo';
import { GET_SETTINGS } from '../../../GlobalQueries/globalQueries';
import Loading from '../../../GlobalComponents/Loading';

const INITIAL_STATE = {	 
    email: '',
	password: '',
	emailIcon:emailIcon,
	keyIcon:key
};

class LoginForm extends Component {
		
  constructor(props){
	   	
	  super(props);
	  
      this.checkLogin = this.checkLogin.bind(this);
	  this.setError  = this.setError.bind(this);
	  this.state = { ...INITIAL_STATE };
	  
  }	
  
  checkLogin(login){
	    
	const {email,password} = this.state;
	  	  
	if(window.navigator.onLine)
	{
		if(password !== '' && email !== '')
		{
			login({variables:{email,password}});  
		}
		else
		{
			this.setState({'error':translate.t('NotFilledEmailOrPwd')});
			setTimeout(() => this.setState({'error':''}),3000);
		}
	}
	else
	{
		this.setState({'error':translate.t('OfflineInternet')});
		setTimeout(() => this.setState({'error':''}),3000);
	}

  }

  setError(error){

	this.setState({error:error});
	setTimeout(() => this.setState({'error':''}),4000);

  }

  render() {

	const {email,password,error} = this.state;  
	  
    return (
        <div id="login" className="admin">
            <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>
			<Query 
				query={GET_SETTINGS}
				variables = {{
					lang:"cz"
				}}
			>
				
				{({data,loading,errro}) => {

					return(
						<div className="card">

							<div className="card-header">{translate.t('AstralProjection')}</div>
							<div className="card-body">

								{loading ?
									<Loading />
								:
									(data.settings.closedPortal == 1 ?
										<p className="text-center">Portál je dočasně uzavřen z důvodu přesunu na nový server. Nebude to dlouho trvat, za chvilku bude opět spuštěný.</p>
									:
										<form onSubmit={this.onSubmit}>
											<div className="form-group">
												
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text" ><img alt="" src={this.state.emailIcon} /></span>
													</div>
													<input
														onMouseEnter={() => this.setState({emailIcon:emailIconA})}
														onMouseOut={() => this.setState({emailIcon:emailIcon})}
														onChange={event => this.setState({'email':event.target.value})}
														value={email}
														type="text" className="form-control" placeholder={translate.t('Email')} aria-label="Username" />
												</div>

											</div>
											<div className="form-group">
																		
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text" ><img alt="" src={this.state.keyIcon} /></span>
													</div>
													<input
														onMouseEnter={() => this.setState({keyIcon:keyA})}
														onMouseOut={() => this.setState({keyIcon:key})}
														onChange={event => this.setState({'password':event.target.value})}
														value={password}
														type="password" placeholder={translate.t('Password')} className="form-control" aria-label="Password" />
												</div>
												
											</div>
											
											<div className="form-group">
											
												<Login login={this.checkLogin} error={this.setError} history={this.props.history} />

											</div>
											
											{ error && <div className="alert alert-danger mt-2 mb-2 text-center">{error}</div> }
											
											<div className="text-center">
												<Link to='/reset-password'>{translate.t('ForgottenPwdQuestion')}</Link>
											</div>
											
										</form>
									)
								}
							</div>
						</div>
					)
				}}
			</Query>
        </div>
    );
  }
}

export default withApollo(LoginForm);
