import React,{ useState,useRef, useEffect } from 'react';
import { withApollo,useQuery,useMutation } from 'react-apollo';
import {SAVE_CHAT_MESSAGE,GET_ALL_CHAT_MESSAGES,UPDATE_CHAT_MESSAGE,UPDATE_NOT_READ_CHAT_MESSAGES,UPLOAD_CHAT_IMAGES} from '../Queries/customer';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';
import ScrollArea from 'react-scrollbar';
import translate from '../../Translations/index';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import close from '../../../Public/Images/Icons/close.webp';
import { UseSocket } from '../../Socket/Components/Socket';
import ChatControls from './ChatControls';
import ChatMessage from './ChatMessage';
import Notification from '../../../Library/notification';
import ChatOptions from './ChatOptions';

var currentMessage;
var selRange = null;
var selectedObj = null;
var limit = 20;
var pointIndex = 20;

const Chat = (props) => {

    var scrolling = false;
    var start = true;
 
    const {
        receiveChatMessageInChat,
        receiveDeletedChatMessage,
        receiveDeletedChatAllMessages,
        SendChatMessage
    } = UseSocket();

    const scrollContainerRef = useRef(null); 
    const divRef = useRef(null);
    const imgDescRef = useRef(null);
    const imgContent = useRef(null);
    const pointMessage = useRef(null);
    const imageList = useRef(null);
    const scrollAfterUpdateMessages = useRef(true);

    const [scrollToPoint, setScrollToPoint] = useState(false);
    const [smoothScrolling, setSmoothScrolling] = useState(false);
    const [firstSend, setFirstSend] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [showUploadImage, SetShowUploadImage] = useState(false);
    const [showUploadImageLoading, SetShowUploadImageLoading] = useState(false);
    const [images, SetImages] = useState([]);
    const [selectedImageIndex, SetSelectedImageIndex] = useState(0);
    const [selectedImageStyle, SetSelectedImageStyle] = useState(null);
    const [allMessages, setAllMessages] = useState([]);

    const [updateChatMessage,{error:updateError}] = useMutation(UPDATE_CHAT_MESSAGE);

    const {data:allDbMessages,error:allMessagesError,loading:allMessagesLoading,fetchMore} = useQuery(GET_ALL_CHAT_MESSAGES,{
        fetchPolicy: 'network-only',
        variables:{
            meID: props.me.customerID,
            vsID: (props.vs ? props.vs.customerID : 0),
            groupID: (props.groupID ? props.groupID : 0),
            limit:limit,
            offset:0,
            useCount: true
        },
        onCompleted:(allDbMessages) => {

            setScrollToPoint(false);
            setAllMessages(allDbMessages.getAllChatMessages);
            
        }

    });

    const [updateNotReadedChatMessages] = useMutation(UPDATE_NOT_READ_CHAT_MESSAGES,{
        onCompleted: (data) => {

            const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});
            var custContext = {...customerContext};

            if(custContext.totalNotReadChatMessages > 0){
                custContext.totalNotReadChatMessages -= data.updateNotReadChatMessages;

                props.client.writeQuery({ 
                    query:GET_HEADER_DATA,
                    data:{
                        customerContext:custContext
                    } 
                }); 
            }

            props.refetchVsList();
   
        }

    });

    useEffect(() => {
        
        if(allDbMessages){
            updateNotReadedChatMessages({
                variables:{
                    meID: props.me.customerID,
                    vsID: (props.vs ? props.vs.customerID : 0),
                    groupID: (props.groupID ? props.groupID : 0)
                }
            })
        }

    },[allDbMessages])

    useEffect(() => {
        
        if(scrollContainerRef.current && scrollAfterUpdateMessages.current){

            if(scrollToPoint){
                if(pointMessage.current)
                {
                    scrollContainerRef.current.scrollArea.scrollYTo(pointMessage.current.offsetTop);
                    setTimeout(() => {
                        setSmoothScrolling(true);
                    },100); 
                }
            }else{
                setTimeout(() => {
                    scrollContainerRef.current.scrollArea.scrollBottom();
                    setSmoothScrolling(true);
                },1);        
            }
        }

        scrollAfterUpdateMessages.current = true;

    },[allMessages])

    useEffect(() => {
    
        if(receiveChatMessageInChat)
        {
            var data = receiveChatMessageInChat;
            if((props.vs && data.toCustomerID == props.me.customerID && data.fromCustomerID == props.vs.customerID)  || data.fromCustomerID == props.me.customerID || (data.toGroupID != 0 &&  data.toGroupID == props.groupID)){

                setScrollToPoint(false);
                            
                setAllMessages((prevData) => {
                    var arr = [...prevData,{
                        customerChatMessagesID: data.customerChatMessagesID,
                        fromCustomerID: data.fromCustomerID,
                        fromCustomer:data.fromCustomer,
                        toGroupID: data.toGroupID,
                        message: data.message,
                        dateAdd: data.dateAdd,
                        image: data.image,
                        width: data.width,
                        height: data.height,
                        readed:1,
                        deleted:0,
                        deletedAtMe:0
                    }];

                    return arr;
                });

                if(data.toCustomerID == props.me.customerID || (data.toGroupID == props.groupID && data.toGroupID != 0 && data.fromCustomerID != props.me.customerID)){

                    updateChatMessage({
                        variables:{
                            customerChatMessagesID:data.customerChatMessagesID,
                            readedCustomerID:props.me.customerID,
                            groupID: (props.groupID ? props.groupID : 0)
                        }
                    })
                }

            }
        }
        
    },[receiveChatMessageInChat])

    useEffect(() => {

        if(receiveDeletedChatMessage)
        {

            var data = receiveDeletedChatMessage;

            var check = false;

            var arr = [...allMessages];
            for(let i = 0; i < allMessages.length; i++)
            {
                if(allMessages[i].customerChatMessagesID == data.customerChatMessagesID)
                {
                    arr[i] = {...arr[i],
                        deleted:data.deleted
                    }   
                    check = true;  
                }

            }

            if(check)
            {
                scrollAfterUpdateMessages.current = false;
                setAllMessages(arr);
            }
            

        }

    },[receiveDeletedChatMessage])

    useEffect(() => {

        if(receiveDeletedChatAllMessages)
        {
            var data = receiveDeletedChatAllMessages;

            if(data.fromCustomerID == props.vs.customerID && data.toCustomerID == props.me.customerID)
            {
                var arr = [...allMessages];
                for(let i = 0; i < allMessages.length; i++)
                {
                    if(allMessages[i].fromCustomerID == props.vs.customerID)
                    {
                        arr[i] = {...arr[i],
                            deleted:data.deleted
                        }     
                    }
                    
                }

                scrollAfterUpdateMessages.current = false;

                setAllMessages(arr);
            }

        }

    },[receiveDeletedChatAllMessages])


    useEffect(() => {

        ChangeImageDimensions();

        window.addEventListener("resize",ChangeImageDimensions);

        return () =>{
            window.removeEventListener("resize",ChangeImageDimensions);
        }
 
    },[showUploadImageLoading,images])

    const [saveChatMessage,{error:saveError}] = useMutation(SAVE_CHAT_MESSAGE,{
        onCompleted:(backData) =>{

            var data = {
                toCustomerID: (props.vs ? props.vs.customerID : 0),
                toGroupID: (props.groupID ? props.groupID : 0),
                fromCustomerID: props.me.customerID,
                fromCustomer:{
                    name: props.me.name,
                    astralName: props.me.astralName,
                    photo: props.me.photo,
                },
                message: backData.saveChatMessage.message,
                dateAdd: backData.saveChatMessage.dateAdd,
                image: backData.saveChatMessage.image,
                width: backData.saveChatMessage.width,
                height: backData.saveChatMessage.height,
                readed:1,
                customerChatMessagesID: backData.saveChatMessage.customerChatMessagesID
            }

            if(!firstSend){
                props.vsListScrollRef.scrollArea.scrollTop();
                setFirstSend(true);
                props.refetchVsList();
            }

            SendChatMessage(data);
            
            currentMessage = "";
            setMessage("");
        }
    });

    const [uploadChatImages,{error:uploadError,loading:uploadLoading}] = useMutation(UPLOAD_CHAT_IMAGES,{
        onCompleted:(res) =>{

            for(let i in images)
            {                
                saveChatMessage({
                    variables:{
                        toCustomerID: (props.vs ? props.vs.customerID : 0),
                        fromCustomerID: props.me.customerID,
                        toGroupID: (props.groupID ? props.groupID : 0),
                        message: images[i].text,
                        image:res.uploadChatImages[i].name,
                        width:res.uploadChatImages[i].width,
                        height:res.uploadChatImages[i].height
                    }
                }); 
            }
            SetShowUploadImageLoading(true);
            CloseImages();
        }
    });

    const sendMessage = (isMainInput) => {

        if(isMainInput && currentMessage && currentMessage != "")
        {
            saveChatMessage({
                variables:{
                    toCustomerID: (props.vs ? props.vs.customerID : 0),
                    fromCustomerID: props.me.customerID,
                    toGroupID: (props.groupID ? props.groupID : 0),
                    message: currentMessage,
                    image: "",
                    width:0,
                    height:0
                }
            })
        }
        else
        {
            SetShowUploadImageLoading(true);

            var imgs = [];
            for(let i in images)
            {
                imgs.push(images[i].file);
            }

            uploadChatImages({
                variables:{
                    images:imgs
                }
            })
        }
    }

    const CloseImages = () => {

        SetImages([]);
        SetSelectedImageIndex(0);
        SetSelectedImageStyle(null);
        SetShowUploadImage(false);

    }

    const RemoveImage = (imgIndex) => {

        if(images.length == 1)
        {
            CloseImages();
        }
        else
        {
            var arr = [...images];
            arr.splice(imgIndex,1);

            if(imgIndex == selectedImageIndex)
            {
                if(imgIndex > 0)
                    SetSelectedImageIndex(imgIndex - 1);
                else
                    SetSelectedImageIndex(0);   
            }
            else if(imgIndex < selectedImageIndex)
                SetSelectedImageIndex(selectedImageIndex - 1);

            SetImages(arr);
        }

    }

    const OpenImages = (e) => {

        SetShowUploadImage(true);
        SetShowUploadImageLoading(true);

        var images = e.target.files;  
        
        var promises = [];
        for(let i = 0; i < images.length; i++)
        {
            let image = images.item(i)
            if(image.type.match('image.*'))
            {
                promises.push(ReadImage(image));  
            }
        }

        e.target.value = null;

        Promise.all(promises).then((images) => {

            var imagesData = [];

            for(let i in images)
            {
                imagesData.push({
                    image:images[i].base64,
                    file:images[i].file,
                    text:""
                });
            }

            if(imagesData.length > 0)
            {
                SetImages(imagesData);
                SetShowUploadImageLoading(false);
            }
            else
            {
                SetShowUploadImage(false);
                SetShowUploadImageLoading(false);

                const notify = new Notification();
                notify.setNotification(null,"Vkládat lze pouze fotky ve formátu JPG, PNG, WEBP a GIF.",false,true,props.client);
            }
            
        });
    }

    const ReadImage = (file) => {

        return new Promise((resolve,rejected) => {

            var reader = new FileReader();
            reader.onload = () => {

                var dataURL = reader.result;
                resolve({
                    base64:dataURL,
                    file:file
                });
        
            };
            reader.readAsDataURL(file);
        });  
    }

    const ChangeImageDimensions = () => {
        
        if(imgContent.current)
        {
            const width = imgContent.current.clientWidth;
            const height = imgContent.current.clientHeight;
            
            SetSelectedImageStyle({
                maxWidth: (width - 40) + "px",
                maxHeight: (height - 40) + "px",
            })
            
        }

        if(imageList.current)
        {
            if(imageList.current.scrollWidth > imageList.current.clientWidth)
                imageList.current.classList.add("justify-flex-start");
            else
                imageList.current.classList.remove("justify-flex-start");
        }
    }

    const handleOnChange = (evt,isMainInput) => {

        currentMessage = evt.target.value;
        if(isMainInput)
            setMessage(currentMessage);
        else{
            var arr = [...images];
            arr[selectedImageIndex] = {...arr[selectedImageIndex],text:currentMessage}

            SetImages(arr);
        }

    }

    const onKeyDownHandle = (e,isMainInput) => {
        if(e.key === 'Enter' && ! e.shiftKey){
            sendMessage(isMainInput);
            e.preventDefault();
            e.stopPropagation();
        }

    }

    const onPaste = (e,isMainInput) => {
        e.preventDefault(); 
        var text = e.clipboardData.getData('text/plain'); 
        
        setTextToPosition(text,isMainInput);
    }

    
    const setTextToPosition = (text,isMainInput) => {

        var elm = divRef.current;
        if(!isMainInput)
            elm = imgDescRef.current;

        var data = getSelectedData();

        if(
            (data.range.commonAncestorContainer.nodeName == "#text" && data.range.commonAncestorContainer.parentNode.className == "message" && data.range.commonAncestorContainer.parentNode.nodeName == "DIV") || 
            (data.range.commonAncestorContainer.className == "message" && data.range.commonAncestorContainer.nodeName == "DIV")){

            var textNode = document.createTextNode(text);
            data.range.insertNode(textNode);
            data.range.setStart(textNode, text.length);

            currentMessage = elm.innerHTML;

            if(isMainInput)
                setMessage(currentMessage);
            else{

                var arr = [...images];
                arr[selectedImageIndex] = {...arr[selectedImageIndex],text: currentMessage}

                SetImages(arr);
            }
                
        }
    }

    const getSelectedData = () => {

        var range;
        var selObj;

        if(selRange){
            range = selRange;
            selObj = selectedObj;
        }else{
            var idoc   = window || document; // ie compatibility
            selObj = idoc.getSelection();
            range  = selObj.getRangeAt(0);
        }

        var sameRange = false;
        if(range.startContainer === range.endContainer)sameRange = true;

        return {
            range: range,
            selObj: selObj,
            sameRange:sameRange
        }

    }

    const setSelectedText = () => {

        var idoc   =  window || document; // ie compatibility
        var Obj = idoc.getSelection();
        selRange = Obj.getRangeAt(0);
        selectedObj = Obj;

    }

    var err = "";
    if(updateError || saveError || allMessagesError || uploadError){
        
        var helper = new Helper();
        err = helper.getApolloErrorText(updateError || saveError || allMessagesError || uploadError);

    }

    var className = "";
    if(props.groupID) className = "is-group";
    if(props.vs && (props.vs.customerTypeID == 2 || props.vs.customerTypeID == 5))
        className += " guide";

    return(
        <div id="chat" className={className}>

            {err != "" ?
                <Error text={err} />
            :
                <>
                    {props.showMessagesOptions ?
                        <ChatOptions 
                            scrollAfterUpdateMessages={scrollAfterUpdateMessages} 
                            allMessages={allMessages} 
                            setAllMessages={setAllMessages} 
                            SetShowMessagesOptions={props.SetShowMessagesOptions} 
                            me = {props.me}
                            vs = {props.vs}
                        />
                    :null}
                    <div className="all-messages">

                        <ScrollArea 
                            ref = {scrollContainerRef}
                            smoothScrolling={smoothScrolling}
                            onScroll = {async (value) => {

                                if(value.topPosition == 0 && !start){

                                    scrolling = false;

                                    var newOffset = allDbMessages.getAllChatMessagesCount - allMessages.length - limit;
                                    var newLimit = limit;

                                    if(newOffset < 0 && newOffset + limit >= 0){
                                        newLimit = newOffset + limit;
                                        newOffset = 0;
                                    }
                                    
                                    if(newOffset >= 0 && newLimit > 0){

                                        setSmoothScrolling(false);
                                        setFetchLoading(true);

                                        var dat = await fetchMore({
                                            variables: {
                                                meID: props.me.customerID,
                                                vsID: (props.vs ? props.vs.customerID : 0),
                                                groupID: (props.groupID ? props.groupID : 0),
                                                offset:newOffset,
                                                limit:newLimit,
                                                useCount: false,
                                            },
                                            updateQuery: (prev, { loading,fetchMoreResult }) => {
                                                return prev.getAllChatMessages;
                                                
                                            }
                                        });

                                        setScrollToPoint(true);
                                        pointIndex = newLimit;

                                        setFetchLoading(false);
                                        
                                        setAllMessages([...dat.data.getAllChatMessages,...allMessages]);
                                    }  
                                }else{
                                    start = false;
                                }
                            }}
                        >
                            {allMessages && allMessages.length > 0 ?
                                <>
                                    {allMessages.map((item,index) => {

                                        if(item.fromCustomerID == props.me.customerID)
                                        {
                                            return(
                                                <div key={index}>
                                                    <div ref={(index == pointIndex ? pointMessage : null)} className="current-message me">
                                                        <ChatMessage 
                                                            scrollAfterUpdateMessages={scrollAfterUpdateMessages} 
                                                            allMessages={allMessages} 
                                                            setAllMessages={setAllMessages} 
                                                            item={item} 
                                                            isVs = {false} 
                                                            containerRef={scrollContainerRef} 
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        else
                                        {
                                            return(
                                                <div key={index} ref={(index == pointIndex ? pointMessage : null)} className="current-message vs">
                                                    <ChatMessage 
                                                        scrollAfterUpdateMessages={scrollAfterUpdateMessages} 
                                                        allMessages={allMessages} 
                                                        setAllMessages={setAllMessages} 
                                                        item={item} 
                                                        isVs = {true} 
                                                        containerRef={scrollContainerRef} 
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                                </>
                            :
                                (!allMessagesLoading ?
                                    <div className="alert alert-warning text-center no-margin">{translate.t("NoChatMessagesYet")}</div>
                                :null)    
                            }
                                                
                        </ScrollArea>
                    </div>
                    
                    <ChatControls
                        key={(props.groupID ? "g-" + props.groupID : "c-" + props.vs.customerID)}
                        text = {message} 
                        showAddImage = {true}
                        isImageInput = {true}
                        onKeyDownHandle = {onKeyDownHandle}
                        innerRef = {divRef}
                        onPaste = {onPaste}
                        handleOnChange = {handleOnChange}
                        setSelectedText = {setSelectedText}
                        OpenImages = {OpenImages}
                        setTextToPosition = {setTextToPosition}
                        sendMessage = {sendMessage}
                        selRange = {selRange}
                    />
                    
                    {showUploadImage ?
                        <div className="add-images-modal">

                            {showUploadImageLoading ?

                                <Loading color="white" />
                            :
                                <>
                                    <img onClick={() => CloseImages()} className="close-images" src={close} />

                                    <div ref={imgContent} className="image">
                                        
                                        {selectedImageStyle ? 
                                            <img style={selectedImageStyle} src={images[selectedImageIndex].image} />
                                        :null}
                                    
                                    </div>

                                    <div ref={imageList} className="images-list">

                                        {images.map((item,index) => (
                                            <div key={index} className="img">
                                                <img className={(selectedImageIndex == index ? "selected" : "")} onClick={() => SetSelectedImageIndex(index)} key={index} src={item.image} />
                                                <div className="close-img">
                                                    <img onClick={() => RemoveImage(index)} src={close} className="close" />
                                                </div>
                                            </div>
                                        ))}
                                            
                                    </div>
                                    <div className="controls">
                                        <ChatControls
                                            key={(props.groupID ? "g-" + props.groupID : "c-" + props.vs.customerID)}
                                            text = {images[selectedImageIndex].text} 
                                            showAddImage = {false}
                                            isImageInput = {false}
                                            onKeyDownHandle = {onKeyDownHandle}
                                            innerRef = {imgDescRef}
                                            onPaste = {onPaste}
                                            handleOnChange = {handleOnChange}
                                            setSelectedText = {setSelectedText}
                                            OpenImages = {OpenImages}
                                            setTextToPosition = {setTextToPosition}
                                            sendMessage = {sendMessage}
                                            selRange = {selRange}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    :null}
                </>  
            }  

        </div>  
                 
    );
}

export default withApollo(Chat);