/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_EVENT_CATEGORIES = gql`
    query GetEventCategories($lang: String!,$regCode:String){
        allEventCategorys(lang: $lang){
            eventCategoryID
            name
            niceName
            active
            lang
            text
        }
        allEventCategoryNotSend(regCode:$regCode){
            customerID
            eventCategoryID
        }

    }
`;

export const ADD_EVENT_CATEGORY_TO_NOT_SEND = gql`
    mutation AddEventCategoryToNotSend($eventCategories:[ID],$regCode:String){
        addEventCategoryNotSends(eventCategories:$eventCategories,regCode:$regCode)
    }
`;