import React,{useEffect,useState,useMemo} from 'react';
import {withApollo,useQuery,useMutation,useSubscription } from 'react-apollo';
import translate from '../../Translations/index';
import Modal from 'react-modal';
import check from '../../../Public/Images/Icons/verified.webp';
import remove from '../../../Public/Images/Icons/remove.webp';
import close from '../../../Public/Images/Icons/close.webp';
import payments from '../../../Public/Images/Icons/payments.webp';
import invoice from '../../../Public/Images/Icons/invoice.webp';
import copy from '../../../Public/Images/Icons/copy.webp';
import moment from 'moment';
import {GET_AFFILIATE_DATA,GET_AFFILIATE_PROFITS} from '../Queries/affiliate.js';
import {UPDATE_PAYU_PAYMENT_SUBSCRIPTION} from '../../PayU/Queries/payu';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import NoItems from '../../../GlobalComponents/NoItems';
import Error from '../../../GlobalComponents/Error';
import {SERVER_URL} from '../../../Config/index';
import { UsePaidOff } from '../Hooks/Affiliate';
import Notification from '../../../Library/notification';
import AddAffiliateUrl from './AddAffiliateUrl';
import NoAccess from '../../../GlobalComponents/NoAccess';

const Affiliate = (props) => {

    const todayYear = parseInt(moment().format("YYYY"));
    const years = [];

    for(let i = 2020; i <= todayYear + 1; i++){
        years.push(i);
    }

    const [tab, SetTab]                      = useState(1);
    const [showModal, setShowModal]          = useState(false);
    const [modalData, setModalData]          = useState(null);
    const [paidOffNotify, showPaidOffNotify] = useState(false);
    const [paymentStatus, setPaymentStatus]  = useState("");
    const [affiliateID, SetAffiliateID]        = useState(0);

    const [formData, setFormData] = useState({
        month: moment().format("M"),
        year:  todayYear,
        countryID: 60,
        bankNumber:"",
        swift:"",
        iban:""
    });

    const {data,loading,error,refetch:affDataRefetch} = useQuery(GET_AFFILIATE_DATA,{
        fetchPolicy: 'network-only',
        onCompleted: (da) => {
            if(da.customerContext.lastCustomerPayment && da.customerContext.lastCustomerPayment.status != "COMPLETED"){
                setPaymentStatus(da.customerContext.lastCustomerPayment.status);
            }
            const bn = (da.customerContext.bankAccount ? da.customerContext.bankAccount : "");
            const sw = (da.customerContext.swift ? da.customerContext.swift : "");
            const ib = (da.customerContext.iban ? da.customerContext.iban : "");

            setFormData({...formData,
                bankNumber:bn,
                swift:sw,
                iban:ib,
                countryID: da.customerContext.countryID
            })
        }
    });

    const { data: subscibeData, loading:subscibeLoading } = useSubscription(UPDATE_PAYU_PAYMENT_SUBSCRIPTION,{
        onSubscriptionData(d){

            if(d.subscriptionData.data.updatePayUPayment && data && data.customerContext && d.subscriptionData.data.updatePayUPayment.customerID == data.customerContext.customerID){
                setPaymentStatus(d.subscriptionData.data.updatePayUPayment.status);
                affDataRefetch();
            }
        }
    });

    const {data:profitData,loading:profitLoading,error:profitError,refetch} = useQuery(GET_AFFILIATE_PROFITS,{
        fetchPolicy: 'network-only',
        variables:{
            month:parseInt(formData.month),
            year:parseInt(formData.year)
        }
    });

    const [paidOffLoading,DoPaidOff] = UsePaidOff(affDataRefetch,showPaidOffNotify,props.client);

    const aff1Urls = useMemo(() => {
        if(data)
            return data.allCustomerAffiliateUrl.filter((item) => item.affiliateID == 1)
        else return []
    }, [data]);

    const aff2Urls = useMemo(() => {
        if(data)
            return data.allCustomerAffiliateUrl.filter((item) => item.affiliateID == 2)
        else return []
    }, [data]);

    useEffect(() => {

        refetch();

    },[formData]);

    const CopyUrl = (text) => {

        const notify = new Notification();
        notify.setNotification(null,translate.t("IsCopiedInMemory"),true,false,props.client);

        navigator.clipboard.writeText(text);

    }

    var err = "";
    if(error || profitError){

        var helper = new Helper();
        err = helper.getApolloErrorText(error || profitError);  
    }

    var totalProfit = 0;
    var totalPaidOff = 0;

    var access = false;
    if((data && (data.customerContext.customerTypeID == 3 || (data.customerContext.membershipTo && !(data.customerContext.lastCompletedCustomerPayment.amount == 0 && moment(data.customerContext.lastCompletedCustomerPayment.dateFrom).add(7,"d").format("YYYY-MM-DD") == moment(data.customerContext.lastCompletedCustomerPayment.dateTo).format("YYYY-MM-DD"))))))
        access = true;

    return(
        <div id="ls-gift" className={"whole-container " + (!access ? " d-flex flex-column justify-content-center align-items-center flex1" : "")}>	

            
            {err != "" ?
                <Error text={err} />
            :
                (access ?

                    <>
                        <ul className="tabs">
                            <li onClick={() => SetTab(1)} className={"tab-item" + (tab == 1 ? " selected" : "")}><span>{translate.t("WantToEarn")}</span></li>
                            <li onClick={() => SetTab(2)} className={"tab-item"+ (tab == 2 ? " selected" : "")}><span>{translate.t("WantToHelp")}</span></li>
                        </ul>
                        <div className="row">
                            <div className={"col-12" + (data && data.customerContext.customerTypeID != 3 && data && data.customerContext.customerTypeID != 4 ? " col-xl-6" : "") + (tab != 1 ? " hidden" : "")}>

                                <h2 className="text-center">{translate.t("WantToEarn")}</h2>

                                <div className="mb-space">
                                    {loading && !data ?
                                        <Loading />
                                    :
                                        <>
                                            <p className="f-14">{translate.t("affiliateURLinfo")}</p>
                                            <p className="f-14">{translate.t("affiliateURLinfo1")}</p>
                                            <p className="f-14">
                                                <strong>{translate.t("affiliateURLinfo2")}</strong>
                                                <br />
                                                <strong>{translate.t("affiliateURLinfo3")}</strong>
                                            </p>
                                            
                                            <p className="f-14">{translate.t("affiliateURLinfo4")}</p>
                                                                                                                                                                    
                                        </>
                                    }
                                    
                                </div>

                                <div className="card">

                                    <div className="card-header justify-content-left">
                                        {translate.t("affiliateURL")} 
                                        {aff1Urls.length < 3 ?
                                            <button onClick={() => SetAffiliateID(1)} className="btn btn-primary ml-auto btn-thiner">{translate.t("Add")}</button>
                                        :null}
                                    </div>
                                    <div className="card-body">
                                        {loading && !data ?
                                            <Loading />
                                        :
                                            <div className="table-responsive">
                                                <p className="f-14">{translate.t("affiliateURLinfo5")}</p>
                                                <table className="table">

                                                    <thead>
                                                        <tr>
                                                            <th>{translate.t("LinkToShareLovelyGift")} "{translate.t("WantToEarn")}"</th>
                                                            <th className="text-right">{translate.t("Copy")}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {aff1Urls.map((item,index) => (

                                                            <tr key={index}>
                                                                <td>https://www.{(data.customerContext.domain.domainName == "astralnaakademia.sk" ? "astralniakademie.cz" : data.customerContext.domain.domainName)}/a/{item.url}</td>
                                                                <td className="text-right">
                                                                    <img onClick={() => CopyUrl("https://www." + (data.customerContext.domain.domainName == "astralnaakademia.sk" ? "astralniakademie.cz" : data.customerContext.domain.domainName) +"/a/" + item.url)} className="cursor-pointer" src={copy} />
                                                                </td>
                                                            </tr>

                                                        ))}
                                                                                                                
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        
                                    </div>

                                </div>
                                <div className="card">

                                    <div className="card-header">{translate.t("VSunderMe")}</div>
                                    <div className="card-body">

                                        {loading ?
                                            <Loading />
                                        :

                                            <div className="table-responsive">
                                                <table className="table">

                                                    <thead>
                                                        <tr>
                                                            <th>{translate.t("Name")}</th>
                                                            <th className="text-center">{translate.t("MembershipTo")}</th>
                                                            <th className="text-right">{translate.t("Payments")}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {data.allParentAffiliateCustomers.map((item,index) => {

                                                            var membership = null;

                                                            if(item.customer.membershipTo){
                                                                membership = item.customer.membershipTo.split(".");
                                                                membership = membership[2] + "-" + membership[1] + "-" + membership[0]; 
                                                            }

                                                            var cl = " badge-success";
                                                            if(!membership || membership == "0000-00-00" || moment(membership + " 00:00:00") < moment().hours(0).minutes(0).seconds(0).milliseconds(0)){
                                                                cl = " badge-danger";
                                                            }
                                                            
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{item.customer.name} {item.customer.surname}</td>
                                                                    <td className="text-center"><span className={"badge" + cl}>{(!item.customer.membershipTo ? translate.t("NoMembership") : (item.customer.membershipTo == "00.00.0000" ? translate.t("NotFinishedPayment") : item.customer.membershipTo))}</span></td>
                                                                    <td className="text-right">
                                                                        <img 
                                                                            className="cursor-pointer"
                                                                            onClick={() => {setShowModal(true);setModalData({
                                                                                name:item.customer.name,
                                                                                surname:item.customer.surname,
                                                                                customerPayments: item.allCustomerPaymentsInAffiliate
                                                                            })}} src={payments} 
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        )}
                                                    </tbody>

                                                </table>

                                                {data.allParentAffiliateCustomers.length == 0 ?
                                                    <NoItems text={translate.t("NoVSunder")} />
                                                :
                                                null}
                                            </div>
                                        
                                        }

                                    </div>

                                </div>

                                <div className="card profits">

                                    <div className="card-header flex-column flex-sm-row">
                                        {translate.t("MyProfits")}
                                        <div className="d-flex align-items-center ml-auto select-container">
                                            <select 
                                                onChange={(e) => setFormData({...formData,month:e.target.value})} 
                                                value={formData.month} 
                                                className="form-control thiner"
                                            >
                                                <option value="1">{translate.t("Leden")}</option>
                                                <option value="2">{translate.t("Únor")}</option>
                                                <option value="3">{translate.t("Březen")}</option>
                                                <option value="4">{translate.t("Duben")}</option>
                                                <option value="5">{translate.t("Květen")}</option>
                                                <option value="6">{translate.t("Červen")}</option>
                                                <option value="7">{translate.t("Červenec")}</option>
                                                <option value="8">{translate.t("Srpen")}</option>
                                                <option value="9">{translate.t("Září")}</option>
                                                <option value="10">{translate.t("Říjen")}</option>
                                                <option value="11">{translate.t("Listopad")}</option>
                                                <option value="12">{translate.t("Prosinec")}</option>
                                            </select>
                                            <select 
                                                value={formData.year} 
                                                className="form-control ml-2 thiner"
                                                onChange={(e) => setFormData({...formData,year:e.target.value})} 
                                            >
                                                {years.map((item,index) => (
                                                    <option value={item} key={index}>{item}</option>
                                                ))}
                                            </select>
                                        </div>    
                                    </div>
                                    <div className="card-body ">

                                        {(profitLoading || loading) && (!profitData || !data) ?
                                            <Loading />
                                        :

                                            <div className="table-responsive">

                                                <table className="table">

                                                    <thead>
                                                        <tr>
                                                            <th>{translate.t("FromVS")}</th>
                                                            {false && <th className="text-center">{translate.t("Value")}</th>}
                                                            <th className="text-right">{translate.t("Profit")}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {profitData.affiliateProfits.map((item,index) => {

                                                            totalProfit += (item.customerPaymentsGroup ? item.customerPaymentsGroup.affiliateProfit : 0);
                                                            
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{item.customer.name} {item.customer.surname}</td>
                                                                    {false && <td className="text-center">{(item.customerPaymentsGroup ? item.customerPaymentsGroup.value : 0)} {data.customerContext.domain.currencyTitle}</td>}
                                                                    <td className="text-right">
                                                                        <strong>
                                                                            {(item.customerPaymentsGroup ? item.customerPaymentsGroup.affiliateProfit : 0)} {data.customerContext.domain.currencyTitle}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                        {profitData.affiliateProfits.length > 0 ?
                                                            <tr className="total">
                                                                <td><strong>{translate.t("Total")}</strong></td>
                                                                <td className="text-right">
                                                                    <strong>
                                                                        {totalProfit} 
                                                                        {data.customerContext.domain.currencyTitle}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            :null
                                                        }
                                                    </tbody>

                                                </table>

                                                {profitData.affiliateProfits.length == 0 ?
                                                    <NoItems text={translate.t("NoProfitsInThisDate")} />
                                                :
                                                null}

                                            </div>
                                        }

                                    </div>

                                </div>


                                <div className="card no-bottom-margin paidoff">

                                    <div className="card-header d-flex align-items-center">
                                        {translate.t("PaidOffs")}   
                                    </div>
                                    <div className="card-body">

                                        {loading ?
                                            <Loading />
                                        :
                                            <div>

                                                <div className="d-flex paid-off align-items-center">

                                                    <div>
                                                        <p className="no-margin">{translate.t("ToPaidOff")}</p>
                                                        <div className="price">{data.totalValueToPaidOff.value} {data.customerContext.domain.currencyTitle}</div>
                                                        <div className="minimal-amount">({translate.t("MinimalAountToWithdraw")})</div>
                                                    </div>
                                                    <div className="ml-auto">
                                                        {((data.totalValueToPaidOff.value >= 1000 && translate.locale == "cz") || (translate.locale == "sk" && data.totalValueToPaidOff.value >= 38)) ?
                                                            <button onClick={() => showPaidOffNotify(true)} className="btn btn-primary btn-block">{translate.t("PaidOff")}</button>
                                                        :
                                                            <div className="btn btn-secondary">{translate.t("PaidOff")}</div>
                                                        }
                                                        
                                                    </div>
                                                    
                                                </div>
                                                
                                                <div className="table-responsive">

                                                    <table className="table">

                                                        <thead>
                                                            <tr>
                                                                <th>{translate.t("PaidOffDate")}</th>
                                                                <th className="text-right">{translate.t("Value")}</th>
                                                                <th className="text-right w-80">{translate.t("Invoice")}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>

                                                            {data.allAffiliatePaidOffs.map((item,index) => {

                                                                totalPaidOff += item.value;

                                                                return(
                                                                    <tr key={index}>
                                                                        <td>{moment(item.date).format("DD.MM.YYYY")}</td>
                                                                        <td className="text-right"><strong>{item.value} {data.customerContext.domain.currencyTitle}</strong></td>
                                                                        <td className="text-right w-80">
                                                                            <a target="_blank" href={SERVER_URL+"/generate/aff-invoice/" + item.hash}>
                                                                                <img className="cursor-pointer" src={invoice} />
                                                                            </a>
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                )

                                                            })}
                                                            
                                                            {data.allAffiliatePaidOffs.length > 0 ?
                                                                
                                                                <tr className="total">
                                                                    <td><strong>{translate.t("Total")}</strong></td>
                                                                    <td className="text-right"><strong>{totalPaidOff} {data.customerContext.domain.currencyTitle}</strong></td>
                                                                    <td></td>
                                                                </tr>

                                                            :null}
                                                        </tbody>

                                                    </table>

                                                    {data.allAffiliatePaidOffs.length == 0 ?
                                                        <NoItems text={translate.t("NoPaidOffs")} />
                                                    :
                                                    null}

                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                            {data && data.customerContext.customerTypeID != 3 && data && data.customerContext.customerTypeID != 4 ?

                                <div className={"col-12 col-xl-6" + (tab != 2 ? " hidden" : "")}>

                                    <h2 className="text-center">{translate.t("WantToHelp")}</h2>

                                    <div className="mb-space">
                                        <p className="f-14">{translate.t("affiliateURLinfo6")}</p>
                                        <p className="f-14">
                                            <strong>{translate.t("affiliateURLinfo7")}</strong>
                                            <br />
                                            <strong>{translate.t("affiliateURLinfo8")}</strong>
                                        </p>
                                        
                                        <p className="f-14">{translate.t("affiliateURLinfo9")}</p>
                                    </div>

                                    <div className="card">

                                        <div className="card-header justify-content-left">
                                            {translate.t("affiliateURL")} 
                                            {aff2Urls.length < 3 ?
                                                <button onClick={() => SetAffiliateID(2)} className="btn btn-primary ml-auto btn-thiner">{translate.t("Add")}</button>
                                            :null}
                                        </div>
                                        <div className="card-body">
                                            {loading && !data ?
                                                <Loading />
                                            :
                                                <div className="table-responsive">
                                                    <p className="f-14">{translate.t("affiliateURLinfo10")}</p>
                                                    <table className="table">

                                                        <thead>
                                                            <tr>
                                                                <th>{translate.t("LinkToShareLovelyGift")} "{translate.t("WantToHelp")}"</th>
                                                                <th className="text-right">{translate.t("Copy")}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            
                                                            {aff2Urls.map((item,index) => (

                                                                <tr key={index}>
                                                                    <td>https://www.{(data.customerContext.domain.domainName == "astralnaakademia.sk" ? "astralniakademie.cz" : data.customerContext.domain.domainName)}/a/{item.url}</td>
                                                                    <td className="text-right">
                                                                        <img onClick={() => CopyUrl("https://www." + (data.customerContext.domain.domainName == "astralnaakademia.sk" ? "astralniakademie.cz" : data.customerContext.domain.domainName) +"/a/" + item.url)} className="cursor-pointer" src={copy} />
                                                                    </td>
                                                                </tr>

                                                            ))}
                                                                
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            
                                        </div>

                                    </div>

                                    <div className="card">

                                        <div className="card-header">{translate.t("VSunderMe")}</div>
                                        <div className="card-body">

                                            {loading ?
                                                <Loading />
                                            :

                                                <div className="table-responsive">
                                                    <table className="table">

                                                        <thead>
                                                            <tr>
                                                                <th>{translate.t("Name")}</th>
                                                                <th className="text-right">{translate.t("MembershipTo")}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {data.allParentAffiliateGiftCustomers.map((item,index) => {

                                                                var membership = null;

                                                                if(item.customer.membershipTo){
                                                                    membership = item.customer.membershipTo.split(".");
                                                                    membership = membership[2] + "-" + membership[1] + "-" + membership[0]; 
                                                                }

                                                                var cl = " badge-success";
                                                                if(!membership || membership == "0000-00-00" || moment(membership + " 00:00:00") < moment().hours(0).minutes(0).seconds(0).milliseconds(0)){
                                                                    cl = " badge-danger";
                                                                }
                                                                
                                                                return(
                                                                    <tr key={index}>
                                                                        <td>{item.customer.name} {item.customer.surname}</td>
                                                                        <td className="text-right"><span className={"badge" + cl}>{(!item.customer.membershipTo ? translate.t("NoMembership") : (item.customer.membershipTo == "00.00.0000" ? translate.t("NotFinishedPayment") : item.customer.membershipTo))}</span></td>
                                                                    </tr>
                                                                )}
                                                            )}
                                                        </tbody>

                                                    </table>

                                                    {data.allParentAffiliateGiftCustomers.length == 0 ?
                                                        <NoItems text={translate.t("NoVSunder")} />
                                                    :
                                                    null}
                                                </div>
                                            
                                            }

                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        {data ?                   
                            <AddAffiliateUrl customer={data.customerContext} affiliateID={affiliateID} SetAffiliateID = {SetAffiliateID} />
                        :null}

                        <Modal
                            className="Modal__Bootstrap modal-lg modal-dialog"
                            closeTimeoutMS={150}
                            isOpen={paidOffNotify}
                            onRequestClose={() => showPaidOffNotify(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header">
                                {translate.t("QuickCheck")}
                                <img src={close} className="close" onClick={() => showPaidOffNotify(false)} />
                            </div>
                            <div className="modal-body">

                                {data && !paidOffLoading ?
                                    
                                    <>
                                        <p>{translate.t("WeWillSendFinanceToThisbankAccount")} <strong>{translate.t("IsStillActual")}</strong> {translate.t("IfNotEditPlease")}</p>
                                        <hr />
                                        <div className={formData.countryID != 60 ? "form-group" : ""}>
                                            <label>{translate.t("BankAccountNumber")}</label>
                                            <div>
                                                <input value={formData.bankNumber} placeholder={translate.t("FillHereYourBankAccount")} onChange={(e) => setFormData({...formData,bankNumber:e.target.value})} className="form-control" type="text" />
                                            </div>
                                        </div>
                                        {formData.countryID != 60 ?
                                            <>
                                                <p><strong></strong>{translate.t("YouAreOutsideOfCzech")}</p>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>{translate.t("Swift")}</label>
                                                            <div>
                                                                <input value={formData.swift} placeholder={translate.t("FillHereYourSwift")} onChange={(e) => setFormData({...formData,swift:e.target.value})} className="form-control" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>{translate.t("Iban")}</label>
                                                            <div>
                                                                <input value={formData.iban} placeholder={translate.t("FillHereYourIban")} onChange={(e) => setFormData({...formData,iban:e.target.value})} className="form-control" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        :null}
                                    </>
                                    
                                :
                                    <Loading />
                                }
                                        
                            </div>

                            <div className="modal-footer"> 
                                <button type="button" className="btn btn-primary flex-fill" onClick={() => DoPaidOff(formData)}>{translate.t("AllRightSendFinance")}</button>
                                <button type="button" className="btn btn-danger" onClick={() => showPaidOffNotify(false)}>{translate.t("Close")}</button>
                            </div>

                            </div>
                        </Modal>

                        <Modal
                            className="Modal__Bootstrap modal-lg modal-dialog"
                            closeTimeoutMS={150}
                            isOpen={showModal}
                            onRequestClose={() => setShowModal(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header">
                                {(modalData ? modalData.name + " " + modalData.surname : "")}
                                <img src={close} className="close" onClick={() => setShowModal(false)} />
                            </div>
                            <div className="modal-body">

                                <div className="table-responsive">
                                    <table className="table no-margin">

                                        <thead>
                                            <tr>
                                                <th>{translate.t("From-To")}</th>
                                                <th className="text-center">{translate.t("Info")}</th>
                                                <th className="text-center">{translate.t("PaidOffed")}</th>
                                                <th className="text-right">{translate.t("PaymentAmount")}</th>
                                            </tr>
                                        </thead>

                                        {modalData ?
                                            <tbody>
                                                {modalData.customerPayments.map((item,index) =>(

                                                    <tr key={index}>
                                                        <td>{item.dateFromString} - {item.dateToString}</td>

                                                        <td className="text-center">
                                                            {(item.commisionInAffiliate == 1 ?
                                                                <span className={"badge " + (item.commisionInAffiliateFrom != "0000-00-00 00:00:00" && moment(item.commisionInAffiliateFrom) > moment() ? "badge-warning" : "badge-success")}>{translate.t("Counted")} {(item.commisionInAffiliateFrom != "0000-00-00 00:00:00"  && moment(item.commisionInAffiliateFrom) > moment() ? translate.t("from") + " " + moment(item.commisionInAffiliateFrom).add(1,"d").format("DD.MM.YYYY") : "")}</span> 
                                                                : 
                                                                <span className="text-red">{translate.t("NotcountedInProfit")}</span>
                                                            )}
                                                        </td>

                                                        <td className="text-center">
                                                            {(item.affiliatePaidOff == 1 ?
                                                                <img src={check} />
                                                                : 
                                                                <img src={remove} />
                                                            )}
                                                        </td>

                                                        <td className="text-right"><strong>{item.amount / 100} {data.customerContext.domain.currencyTitle}</strong></td>
                                                    </tr>

                                                ))}

                                            </tbody>
                                        :
                                            null
                                        }

                                    </table>
                                </div>

                                {modalData && modalData.customerPayments.length == 0 ?
                                    <NoItems text={translate.t("NoCustomerPayments")} />
                                :
                                null}

                                        
                            </div>

                            <div className="modal-footer"> 
                                <button type="button" className="btn btn-danger" onClick={() => setShowModal(false)}>{translate.t("Close")}</button>
                            </div>

                            </div>
                        </Modal>

                    </>
                :
                    (data ?
                        <NoAccess customer={data && data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? null : data && data.customerContext} showFooter = {true} title={translate.t("LovelyGift")} text={data && data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? translate.t("LovelyGiftNoAccessInfoFreeWeek") : translate.t("LovelyGiftInfo")} />           
                    :null)
                )
            }

        </div>
    );
    
}

export default withApollo(Affiliate);