import React,{ Component } from 'react';
import NoMembership from '../Layout/NoMembership';
import translate from '../Modules/Translations/index';
import ReNewButton from '../Modules/PayU/Components/ReNewButton';

const NoAccess = (props) => {
  	
    return (
    
        <div id="no-access" className="card">
            <div className="card-header">{props.title}</div>
            <div className="card-body">   
                <p>{props.text} </p>

                {props.customer ?
                    <ReNewButton firstPayment = {(props.customer.membershipTo != null ? false : true)} regCode={props.customer.regCode} text={translate.t("ActivateMembership")} />
                :null}    
            </div>    
        </div>
        
   
    );
  
}

export default NoAccess;

