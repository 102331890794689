import { useEffect,useState } from "react";
import { withApollo } from "react-apollo";
import alarmA from '../../../Public/Images/AnimatedIcons/alarm.webp';
import alarm from '../../../Public/Images/Icons/alarm.webp';
import ModalNotification from "./ModalNotification";
import NotificationsList from "./NotificationsList";
import UseNotification from "../Library/UseNotification";
import { UseSocket } from "../../Socket/Components/Socket";

const Notifications = ({client,customerID,notReadNotificationCount, lastNotReadPopUpNotification}) => {

    const notificationListVariables = {
        hours:24
    };

    const {receiveNewNotifications} = UseSocket();
    const {
        selectedNotification,
        SelectNotification,
        UpdateNotReadNotificationCount
    } = UseNotification(client,notificationListVariables);

    const [showNotifications,SetShowNotifications] = useState(false);

    useEffect(() => {

        if(lastNotReadPopUpNotification)
            SelectNotification(lastNotReadPopUpNotification);

    },[lastNotReadPopUpNotification]);

    useEffect(() => {

        if(receiveNewNotifications)
        {
            for(let val of receiveNewNotifications.selectedCustomers)
            {
                if(val == customerID)
                    UpdateNotReadNotificationCount("add");
            }
        }

    },[receiveNewNotifications])

    return(
        <>
            <button onClick = {() => SetShowNotifications(!showNotifications)}>
                {notReadNotificationCount != 0 ?
                    <img src={alarmA} />
                :
                    <img src={alarm} />
                }
            </button>

            {notReadNotificationCount != 0 ?
                <div className="badge badge-danger">
                    {notReadNotificationCount}
                </div>
            :null}

            {showNotifications ? 
                <>
                    <NotificationsList
                        SelectNotification = {SelectNotification}
                        notificationListVariables = {notificationListVariables}
                    />
                    <div 
                        className="notification-list-overlay"
                        onClick = {() => SetShowNotifications(false)}
                    ></div>
                </>
            :null}

            {selectedNotification ?
                <ModalNotification
                    SelectNotification = {SelectNotification}
                    notification = {selectedNotification}
                />
            :null}
        </>
    )
}

export default withApollo(Notifications);