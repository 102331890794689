/*
    Úvodní layout administrace
*/
import React,{ Component, useState } from 'react';
import Header from './Header';
import NoMembership from "./NoMembership";
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Affiliate from '../Modules/Affiliate/Components/Affiliate';
import Room from '../Modules/Room/Components/Room';
import ObserverWrapper from '../Modules/Observer/Components/ObserverWrapper';
import Events from '../Modules/Event/Components/Event';
import CustomerDetails from '../Modules/Customer/Components/CustomerDetails';
import EmailSmsNotification from '../Modules/EmailSmsNotification/Components/EmailSmsNotification';
import WelcomePage from '../Modules/Customer/Components/WelcomePage';
import VSList from '../Modules/Customer/Components/VSList';
import EliteVS from '../Modules/Customer/Components/EliteVS';
import Privacy from '../Modules/Customer/Components/Privacy';
import {GET_HEADER_DATA,GET_SETTINGS} from '../GlobalQueries/globalQueries';
import Material from '../Modules/Material/Components/Material';
import Daily from '../Modules/Daily/Components/Daily';
import MyVS from '../Modules/Guide/Components/MyVS';
import RoomsSettings from '../Modules/Room/Components/Settings';
import TrialPeriodSettings from '../Modules/Customer/Components/TrialPeriodSettings';
import Intensively from '../Modules/Services/Components/Intensively';
import DailySettings from '../Modules/Daily/Components/DailySettings';

import {GET_CUSTOMER_DETAILS} from '../Modules/Customer/Queries/customer';
import translate from '../Modules/Translations/index';
import {useQuery,withApollo} from 'react-apollo';

import { UseSocket } from '../Modules/Socket/Components/Socket';
import { useEffect } from 'react';

const Layout = (props) => {

	const [socketData,setSocket] = useState(null);

	const {SetConnection,receiveChatMessage} = UseSocket();
	
	const {data} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
        onCompleted: (da) => {

            if(da.customerContext.domain.lang){
				translate.locale = da.customerContext.domain.lang;    
            }
        }
    });

	const {data:settingsData} = useQuery(GET_SETTINGS,{
		fetchPolicy: 'network-only',
		variables:{
		  lang:translate.locale,
		  refresh: props.location.pathname
		}
	});

	useEffect(() => {

		if(settingsData && settingsData.settings)
		{
			var version = localStorage.getItem("version");
			if(version != settingsData.settings.version)
			{
				localStorage.setItem("version",settingsData.settings.version);
				window.location.reload(true);
			}  

			if(settingsData.settings.closedPortal == 1)
			{
				window.location.href="/";
			} 
		}
		  
	},[settingsData])

	useEffect(() => {
		SetConnection();
	},[])

	useEffect(() => {

		if(data && receiveChatMessage && props.history.location.pathname != "/vs-list")
		{
			var check = false;
			for(let i = 0;i < data.customerContext.groups.length;i++){
				if(data.customerContext.groups[i].groupID == receiveChatMessage.toGroupID && data.customerContext.groups[i].acceptInvitation == 1){
					check = true;
				}
			}

			if(receiveChatMessage.toCustomerID == data.customerContext.customerID || (receiveChatMessage.toGroupID != 0 && check)){

				const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});
				var custContext = {...customerContext};

				custContext.totalNotReadChatMessages++;
					
				props.client.writeQuery({ 
					query:GET_HEADER_DATA,
					data:{
						customerContext:custContext
					} 
				}); 
			}
		}

	},[receiveChatMessage])
  	
    return (

			   
    	<div className="wholeContent" id={"l-" + translate.locale}>			       
 	      
 	        <div id="layout">	
				<Header />  
				<NoMembership />
				
				<Switch>
        
					<Route exact path='/my-rullers-of-the-force' component={MyVS}></Route>
					<Route exact path='/daily'component={Daily}></Route>
					<Route exact path='/materials' component={Material}></Route>
					<Route exact path='/affiliate' component={Affiliate}></Route>
					<Route exact path='/room/:hash' render={(props) => (<Room {...props} socket={socketData} />)}/>
					<Route exact path='/observer/:hash' component={ObserverWrapper}></Route>
					<Route exact path='/aula/:hash' render={(props) => (<Room {...props} socket={socketData} />)}/>
					<Route exact path='/events' component={Events}></Route>
					<Route exact path='/elite' component={EliteVS}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					<Route exact path='/settings/personal-details' component={CustomerDetails}></Route>
					<Route exact path='/settings/notifications' component={EmailSmsNotification}></Route>
					<Route exact path='/settings/privacy' component={Privacy}></Route>
					<Route exact path='/settings/trial-period' component={TrialPeriodSettings}></Route>
					<Route exact path='/settings/rooms' component={RoomsSettings} ></Route>
					<Route exact path='/settings/daily' component={DailySettings} ></Route>
					<Route exact path='/welcome-page' component={WelcomePage}></Route>
					<Route exact path='/vs-list' component={VSList}></Route>
					<Route exact path='/vs-list/:regCode' component={VSList}></Route>
					<Route exact path='/vs-list/:regCode/:messages' component={VSList}></Route>
					<Route exact path='/intensively' component={Intensively}></Route> 

				</Switch>
				
			</div>
		     	       
		</div>
   
    );
  
}

export default withAdminAuthentication(withApollo(Layout));
