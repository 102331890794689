import gql from 'graphql-tag';

export const UPDATE_PAYU_PAYMENT_SUBSCRIPTION = gql`
  subscription UpdatePayUPayment{
    updatePayUPayment{
        customerID
        status
    }
  }
`;

export const SEND_PAYMENT_CANCELED_EMAIL = gql`
    mutation SendPaymentCanceledEmail(
        $membershipDuration:ID,
        $customerPaymentID:ID,
        $name: String,
        $surname: String,
        $email: String,
        $domain:String
        $tel:String
    ){
        sendPaymentCanceledEmail(
            membershipDuration:$membershipDuration,
            customerPaymentID:$customerPaymentID,
            name:$name,
            surname:$surname,
            email:$email,
            domain:$domain
            tel:$tel
        )    
    }
`;

export const CREATE_PAYMENT = gql`
    mutation CreatePayment(
        $customerID:ID!,
        $token:String!,
        $membershipDuration:ID!,
        $name: String,
        $surname: String,
        $email: String,
        $city: String,
        $street: String,
        $zip: String
        $autoPayment:Boolean
        $domain:String
        $tel:String
        $serviceID: ID
        $customerServiceID:ID
    ){
        createPayment(
            customerID:$customerID,
            token:$token,
            membershipDuration:$membershipDuration,
            name:$name,
            surname:$surname,
            email:$email,
            city:$city,
            street:$street,
            zip:$zip,
            autoPayment:$autoPayment
            domain:$domain
            tel:$tel
            serviceID:$serviceID
            customerServiceID:$customerServiceID
            useCreditCardForPayment:1
            url:""
            hasStoredPaymentMethod:false
            customerIDForServicePayment:0
        ){
            customerPaymentID
            status
            clientSecret
        }     
    }
`;

export const GET_RENEW_MEMBERSHIP_DATA = gql`
    query ReNewMembershipData($lang:String,$domainID:ID,$regCode:String) {
        customerByRegCode(regCode:$regCode){
            customerID,
            email,
            name,
            surname,
            street,
            city,
            zip,
            company,
            ic,
            dic,
            domainID
            regCode
            membershipTo
            membershipDuration
            membershipPrice
            tel
            countryID
            lastCompletedCustomerPayment{
                dateTo
            }
            domain{
                domainName
                currencyTitle
                posID
                lang
            }
            affiliateGiftCustomer{
                discount
                discountRepeatPaymentDays
                usingDiscount
                membershipDurationID
            }
        }
        allCountries{
            countryID
            name
        }
        domainData(domainID:$domainID){
            domainName
            currencyTitle
            posID
            lang
        }
        allAdminMembershipDurations(lang:$lang,onlyActive:false){
            membershipDurationID
            name
            value
            firstValue
            trialPeriod
            discount
            discount2
            discountValidityFrom
            discountValidityTo
            discountRepeatPaymentDays
            activeFrom
            activeTo
            description
            onlyInPopup
            autoPaymentsNumber
            length
            lang
            affiliateGiftMembershipDiscount{
                discount
                stripeCouponID
            }
        }
        settings(lang:$lang){
			closePortal
		}
        
    }
`;

