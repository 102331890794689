/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';


export const GET_ROOM_DATA = gql`

    query GetRoomData($lang:String){
        customerContext{
            customerID,
            bankAccount,
            email,
            astralName,
            roomName,
            name,
            surname,
            street,
            city,
            zip,
            domainID
            regCode
            membershipTo
            membershipDuration
            customerTypeID
            tel
            countryID
            myMotivation
            introduction
            actualJob
            idealJob
            iAmGoodAt
            aboutMe
            photo
            welcomePageStep
            groups{
                acceptInvitation
                groupID
            }
            domain{
                domainName
                currencyTitle
                posID
                lang
            }
            lastCustomerPayment{
                status
            }
            customerLastMaterial{
                materialID
                longName
                description
            }
            materials{
                materialID
            }
            rules{
                showForum
                showAffiliate
                showDaily
                showMaterial
                showMyVS
                showRooms
                showRoomsWithNoMembership
                showPreparing
                showMembershipButton
                showEventsCalendar
                showPageAfterReg
                showVsList
                showSettingsRooms
                showEliteVS
                showIntensively
                showObserver
            }
            customerPrivacy{
                customerID
                privacyID
            }
        }
        settings(lang:$lang){
            openAula
        }
    }
`;