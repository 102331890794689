import React, { useEffect, useRef, useState } from 'react';
import {withApollo } from 'react-apollo';
import UseObserver from '../Library/UseObserver';
import { useScript } from '../../../Library/hooks';
import Participants from './Participants';
import Error from '../../../GlobalComponents/Error';
import { UseSocket } from '../../Socket/Components/Socket';
import budha from '../../../Public/Images/Icons/budha.webp';
import translate from '../../Translations/index';
import Loading from '../../../GlobalComponents/Loading';

const Observer = ({roomName,JitsiMeetJS}) => {

    const options = {
        hosts: {
            domain: 'zijinovy.laskyplnysvet.cz',
            anonymousdomain: 'guest.zijinovy.laskyplnysvet.cz',
            muc: 'conference.zijinovy.laskyplnysvet.cz',
        },
        serviceUrl:'https://zijinovy.laskyplnysvet.cz/http-bind'
    };

    const observerRef = useRef();
    const {socket} = UseSocket();

    const [isRoomOpened, SetIsRoomOpened] = useState(true);

    const {
        participants,
        error,
        tracksDimensions,
        InitConnection,
        Disconnect,
        SetParticipants
    } = UseObserver(options,roomName);

    useEffect(() => {

        socket.emit('is-room-open', roomName);
        socket.on("is-room-open", (data) => {

            SetParticipants([]);
            Disconnect();

            if(data.value)
            {
                SetIsRoomOpened(true);
                InitConnection(JitsiMeetJS,observerRef,roomName); 
            }
            else
                SetIsRoomOpened(false);
        });
 
        return () => {
            Disconnect();
            socket.removeAllListeners("is-room-open");
        }

    },[roomName])
         
    return(
        <div className={"whole-container" + (!isRoomOpened || participants.length === 0  ? " flex-1 justify-content-center align-items-center d-flex" : "")}>	

             <div ref={observerRef} className="observer-container">

                {error ? 
                    <Error text = {error} />
                :
                    (!isRoomOpened ?
                        <div className="waiting-info">
                            <img src={budha} className="room-icon" />
                            <p>{translate.t("WaitUntilRoomIsOpen")}</p>
                        </div>
                    :   
                        (participants.length === 0 ?
                            <Loading />
                        : 
                            <>
                                {participants.map((data,index) => {

                                    return(
                                        <Participants 
                                            key={data.participantID} 
                                            data = {data}  
                                            tracksDimensions = {tracksDimensions}
                                        />
                                    )

                                })}
                            </>
                        )
                    )
                }

             </div>
              
        </div>
    );
    
}

export default Observer;