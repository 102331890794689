/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState } from 'react';
import logo from '../../../Public/Images/logo.svg';
import translate from '../../Translations/index';
import ReNewButton from './ReNewButton';

const Payment = (props) => {

    var regCode = props.match.params.regCode;
		   		  
    return (
        <div id="login" className="admin">
            <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>
        	<div className="card">

	        	<div className="card-header">{translate.t('AstralProjection')}</div>
				<div className="card-body text-center">

                    <ReNewButton fromLink = {true} autoOpenModal = {true} regCode={regCode} text={translate.t("DoPay")} />
	        	</div>
        	</div>
        </div>
    );
  
}

export default Payment;
