import React,{useState} from 'react';
import {useMutation,withApollo,useQuery} from 'react-apollo';
import Modal from 'react-modal';
import translate from '../../Translations/index';
import Group from '../Library/group';
import {GET_GROUP_SEARCH_DATA,GET_GROUP_MEMBERS_DATA,ADD_GROUP_CUSTOMER,REMOVE_CUSTOMER_FROM_GROUP} from '../Queries/group';
import Notification from '../../../Library/notification';
import SearchInput from '../../../GlobalComponents/SearchInput';
import {SERVER_URL} from '../../../Config/index';
import user from '../../../Public/Images/user.svg';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import remove from '../../../Public/Images/Icons/remove.webp';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import close from '../../../Public/Images/Icons/close.webp';

const GroupMembers = (props) => {

    var groupLib = new Group(props);

    const [er,setError] = useState(null);
    const [selectedCusotmerToAdd,setSelectedCustomerToAdd] = useState(null);
    const [selectedCusotmerToRemove,setSelectedCustomerToRemove] = useState(null);

    const [allVsData,setAllVs] = useState({
        searchText:"",
        allVS:null
    });

    const {data,loading,error} = useQuery(GET_GROUP_MEMBERS_DATA,{
        fetchPolicy:"network-only",
        variables:{
            groupID:props.groupID
        }
    })

    const [addCustomerToGroup,{error:addError,loading:addLoading}] = useMutation(ADD_GROUP_CUSTOMER,{
        onCompleted:(backData) =>{
            
            const {allGroupCustomers} = props.client.readQuery({ query: GET_GROUP_MEMBERS_DATA,variables:{
                groupID:props.groupID
            }});
            
            var dataToAdd = backData.addGroupCustomer;
            dataToAdd.customer = {
                astralName:selectedCusotmerToAdd.astralName,
                name:selectedCusotmerToAdd.name,
                photo:selectedCusotmerToAdd.photo,
                __typename: "Customer"
            }

            var check = false;
            for(let i  = 0; i < allGroupCustomers.length;i++){
                if(backData.addGroupCustomer.customerID == allGroupCustomers[i].customerID)check = true;
            }

            if(!check){

                var arr = [dataToAdd,...allGroupCustomers]; 
                
                props.client.writeQuery({ 
                    query:GET_GROUP_MEMBERS_DATA,
                    variables:{
                        groupID:props.groupID
                    },
                    data:{
                        allGroupCustomers:arr
                    } 
                }); 
            }

            getVsList(null);
            setSelectedCustomerToAdd(null);

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyAdded"),true,false,props.client);
        }
    });

    const [removeCustomerFromGroup,{error:removeError,loading:removeLoading}] = useMutation(REMOVE_CUSTOMER_FROM_GROUP,{
        onCompleted:(backData) =>{
            
            const {allGroupCustomers} = props.client.readQuery({ query: GET_GROUP_MEMBERS_DATA,variables:{
                groupID:props.groupID
            }});
            

            var arr = [...allGroupCustomers]; 

            for(let i = 0; i < arr.length;i++){
                if(arr[i].groupCustomerID == selectedCusotmerToRemove.groupCustomerID){
                    arr.splice(i,1);
                }
            }
            
            props.client.writeQuery({ 
                query:GET_GROUP_MEMBERS_DATA,
                variables:{
                    groupID:props.groupID
                },
                data:{
                    allGroupCustomers:arr
                } 
            }); 

            setSelectedCustomerToRemove(null);

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyRemoved"),true,false,props.client);

        
        }
    });

    const addMember = (act) => {

        if(act){
            addCustomerToGroup({
                variables:{
                    groupID: props.groupID,
                    customerID: selectedCusotmerToAdd.customerID,
                    creator:0,
                    acceptInvitation:0
                }
            })
        }
    }

    const removeMember = (act) => {

        if(act){
            removeCustomerFromGroup({
                variables:{
                    groupCustomerID: selectedCusotmerToRemove.groupCustomerID
                }
            })
        }

    }


    const getVsList = async (text) => {

        if(text && text != ""){

            try{

                var allVS = await props.client.query({
                    query: GET_GROUP_SEARCH_DATA,
                    fetchPolicy: 'network-only',
                    variables:{
                        text:text,
                        offset:0,
                        limit:50
                    }
                });

                setAllVs({...allVsData,
                    searchText:text,
                    allVS:allVS.data.allCustomersWithActiveMembership
                });

            }catch(err){
                setError(err);
            }
        }else{
            setAllVs({...allVsData,
                searchText:"",
                allVS:null
            });
        }

    }

    var err = "";
    if(error || addError){        
        var helper = new Helper();
        err = helper.getApolloErrorText(error || addError);
    }

    if(er){
        err = er;
    }

    return(
        
        <Modal
            className="Modal__Bootstrap modal-lg modal-dialog"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.showGroupMemebers(false)}
        >
            <div className="modal-content">

                <div className="modal-header">
                    {translate.t("GroupMembers")}
                    <img src={close} className="close" onClick={() => props.showGroupMemebers(false)} />
                </div>
                <div className="modal-body">

                    {err != "" ?
                        <Error text={err} />
                    :
                        <>
                            {props.creator == 1 ?
                                <>
                                    <div className="search">
                                        <label>{translate.t("InviteMember")}</label>
                                        <SearchInput value={allVsData.searchText} className="form-control" placeholder={translate.t("SearchVS")} onChange={(text) => getVsList(text)} />
                                    </div>

                                    {allVsData.allVS ?
                                        <div>
                                            {allVsData.allVS.map((item,index) => (
                                                <div className="group-memvers-list" key={index} onClick={() => setSelectedCustomerToAdd(item)}>
                                                    <div className="img">
                                                        <img src={(item.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + item.photo :user)} />
                                                    </div>
                                                    <div className="info">
                                                        {(item.astralName != "" ? item.astralName : item.name)}
                                                    </div>
                                                    <button className="ml-auto btn btn-primary btn-thiner">{translate.t("Invite")}</button>
                                                </div>
                                            ))}

                                            {allVsData.allVS.length == 0 ?
                                                <div className="alert alert-warning mt-3 text-center">{translate.t("NoActiveVSFound")}</div>
                                            :null}
                                        </div>
                                    :null}
                                </>
                            :null}

                            <div className={"card no-bottom-margin" + (props.creator == 1 ? " mt-3" : "")}>

                                {props.creator == 1 ?
                                    <div className="card-header">
                                        {translate.t("MemebrsList")}
                                    </div>
                                :null}

                                <div className="card-body">

                                    {loading ?
                                        <Loading />
                                    :
                                        <div>
            
                                            {data.allGroupCustomers && data.allGroupCustomers.map((item,index) => (
                                                <>
                                                    {item.customer ?
                                                        <div className="group-memvers-list no-hover" key={index}>
                                                            <div className="img">
                                                                <img src={(item.customer.photo != "" ? SERVER_URL + "/Public/Images/Avatar/stredni_" + item.customer.photo :user)} />
                                                            </div>
                                                            <div className="info">
                                                                {(item.customer.astralName != "" ? item.customer.astralName : item.customer.name)}
                                                                
                                                            </div>
                                                            {(item.acceptInvitation == 0 ? 
                                                                <div className="accept-invitation">{translate.t("InvitationNotAcceptedYet")}</div>
                                                            :null)}
                                                            {props.creator == 1 && item.creator == 0 ?
                                                                <img onClick={() => setSelectedCustomerToRemove(item)} className="ml-auto remove" src={remove} />
                                                            :null}
                                                        </div>
                                                    :null}
                                                </>

                                            ))}
       
                                        </div>
                                    }
                                </div>

                            </div>
                        </>
                    }
              
                </div>

                <div className="modal-footer">                  
                    <button type="button" className="btn btn-danger" onClick={() => props.showGroupMemebers(false)}>{translate.t("Close")}</button>
                </div>

            </div>

            {selectedCusotmerToAdd ?
                <ModalNotification yesNo = {true} text={translate.t("DoYouRealyWantToInviteThisVsAsMember")} callback={addMember} />
            :null}

            {selectedCusotmerToRemove ?
                <ModalNotification yesNo = {true} text={translate.t("DoYouRealyWantToRTemoveThisVsFromGroup")} callback={removeMember} />
            :null}   

        </Modal>
           
    );

}

export default withApollo(GroupMembers);