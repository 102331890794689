import React, {Component} from 'react';
import {ApolloClient} from 'apollo-client';
import { ApolloProvider} from "react-apollo";
import {ApolloLink,concat,split} from 'apollo-link';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import {GRAPHQL_SERVER_URL,GRAPHQL_SERVER_WS_URL} from './Config/index';
import withTranslation from './Modules/Translations/withTranslation';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { SocketProvider } from './Modules/Socket/Components/Socket';
import { ThemeProvider } from './GlobalComponents/Theme';

// routování url adres
import Routes from './Routes/index.js'; 

//resolvery a schema pro lokalní uložení
import {resolvers, typeDefs} from './Resolvers/resolvers';
import {STRIPE_PKEY} from "./Config/index";
const stripePromise = loadStripe(STRIPE_PKEY);

// inicializace Cache - dá se do této cache ukládat hodnoty pro příští použití
const cache  = new InMemoryCache();

// URl adresa na server pro graphql
const httpLink = new createUploadLink({ 
	uri: GRAPHQL_SERVER_URL,
	 
});

const wsLink = new GraphQLWsLink(createClient({
	url: GRAPHQL_SERVER_WS_URL,
}));

//prostředník, který při každém requestu zasílá token na server z localstorage
const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	operation.setContext({
	  headers: {
		authorization: localStorage.getItem('token') || null,
	  }
	});
  
	return forward(operation);
});

const link = split(
	// split based on operation type
	({ query }) => {
	  const { kind, operation } = getMainDefinition(query);
	  return kind === 'OperationDefinition' && operation === 'subscription';
	},
	wsLink,
	concat(authMiddleware, httpLink),
  );


//Apollo klient, který se přihlašuje na server
const client = new ApolloClient({
  cache,
  link, 
  resolvers,
  typeDefs
});

//do cache se ukládá, jestli jsme přihlášení nebo ne
cache.writeData({
	data:{
		isLoggedIn: !!localStorage.getItem('token'),
		notifications:[]
	}
});


class App extends Component {
  
    render(){	
	    return (
		    <ApolloProvider client={client}>
				<SocketProvider>
					<ThemeProvider>
						<Elements stripe={stripePromise}>
							<Routes />
						</Elements>
					</ThemeProvider>
				</SocketProvider>
	        </ApolloProvider>
	    );
    }
}

export default  withTranslation(App,client);
