import React,{forwardRef,useEffect,useState} from 'react';

const QuestionnaireText = forwardRef((props,ref) => {

    const [text, setText] = useState("");

    const setData = (value) => {
        setText(value);
        if(props.callBack)props.callBack(value);
    }

    useEffect(() => {
        if(props.text){
            setText(props.text);
        }
    },[])

    return(
        <div className="step" ref={ref}>
            <label className="input-label">{props.question}</label>
            <div className={"form-group" + (props.disabled ? " no-bottom-margin" : "")}>
                {props.disabled ?
                    <p className="no-bottom-margin f-14">{text}</p>
                :
                    <input className="form-control" type="text" value={text} name="email" onChange={(e) => setData(e.target.value)} />
                }
                
            </div>
        </div>

    )

})

export default QuestionnaireText;