/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_CUSTOMER_DAILY_DATA = gql`
    query GetCustomerDailyData(
        $customerID:ID,
        $offset:Int,
        $limit:Int,
        $messageType:Int,
        $dateFrom:String,
        $dateTo:String
    ){
        
        allCustomerDailyPosts(
            customerID:$customerID,
            offset:$offset,
            limit:$limit,
            messageType:$messageType,
            dateFrom:$dateFrom,
            dateTo:$dateTo
        ){
            customerDailyPostID
            guideID
            dateAdd
            text
            readByGuide
            customerID
            guide{
                name
                surname
                astralName
            }
        }
        allCustomerDailyPostsCount(
            customerID:$customerID,
            messageType:$messageType,
            dateFrom:$dateFrom,
            dateTo:$dateTo
        )
        allMessageTypeNotReadMessagesCount(customerID:$customerID){
            messages
            myProgress
            dreams
            sync
            intangibleExperiences
            negativCharacteristics
            positiveCharacteristics
            charismaticEssence
            joys
            transformation
            lifeValues
            meaningOfLife
            wishes
            dreamEightPlusOne
            actor
            previousLifes
            personalNotes
            guide
            challengeAbundance
        }
        customerContext{
            customerTypeID
            customerID
            myMotivation
            customerDailySettings{
                dailySettingsID
                dailySettings{
                    label
                }
            }
        }
    }
`;

export const GET_CUSTOMER_DAILY_POST = gql`
    query GetCustomerDailyPost($customerDailyPostID:ID){
        customerDailyPost(customerDailyPostID:$customerDailyPostID){
            text
        }
    }
`;

export const GET_DAILY_SETTINGS = gql`
    query GetDailySettings{
        AllDailySettings{
            dailySettingsID
            label
        }
        customerContext{
            customerID
            customerDailySettings{
                dailySettingsID
            }
        }
    }
`;

export const ADD_CUSTOMER_DAILY_POST = gql`
    mutation AddCustomerDailyPost(
        $customerDailyPostID:ID, 
        $customerID:ID!,
        $guideID:ID,
        $text:String,
        $messageType:Int,
        $autoSave:Boolean
    ){
        addEditCustomerDailyPost(
            customerDailyPostID:$customerDailyPostID,
            customerID:$customerID,
            guideID:$guideID,
            text:$text,
            messageType:$messageType
            autoSave: $autoSave
        ){
            customerDailyPostID
            guideID
            dateAdd
            text
            autoSave
            guide{
                name
                surname
                astralName
            }
        }
    }
`;

export const DELETE_CUSTOMER_DAILY_POST = gql`
    mutation DeleteCustomerDailyPost($customerDailyPostID:ID){
        deleteCustomerDailyPost(customerDailyPostID:$customerDailyPostID)
    }
`;

export const UPDATE_NOT_READ_POSTS = gql`
    mutation UpdateNotReadPosts($customerID:ID,$messageType:ID){
        updateNotReadPosts(customerID:$customerID,messageType:$messageType)
    }
`;

export const SAVE_CUSTOMER_DAILY_SETTINGS = gql`
mutation SaveCustomerDailySettings($dailySettingsID:ID,$value:String){
    SaveCustomerDailySettings(dailySettingsID:$dailySettingsID,value:$value)
}
`;