import gql from 'graphql-tag';

export const SET_NOTIFICATION_IS_READ = gql`
    mutation SetNotificationIsRead($notificationID:ID){
        setNotificationIsRead(notificationID: $notificationID)
    }
`;

export const GET_LAST_NOTIFICATIONS_BY_TIME = gql`
    query LastCustomerNotificationsByTime($hours:Int){
        LastCustomerNotificationsByTime(hours: $hours){
            notificationID
            title
            text
            isRead
        }
    }
`;