import React,{Fragment} from 'react';

const SubMaterial = (props) => {

    const setSelectedMaterials = (item, parrentID) => {

        var materialID = item.materialID;

        var arr = [...props.selectedMaterials];

        var checkedParent = false;
        for(let i in arr){
            if(parrentID == arr[i]){
                checkedParent = true;
            }
        } 

        if(parrentID != 0){

            var deleteRest = false;
            var stop = false
            for(let val of props.materials){

                var checked = false;
                for(let i in arr){
                    if(val.materialID == arr[i]){
                        checked = true;
                        if(materialID == val.materialID){
                            deleteRest = true;
                        }
                    }
                } 

                if(deleteRest){
                    for(let i in arr){
                        if(val.materialID == arr[i] && materialID != val.materialID){
                            arr.splice(i,1); 
                        }
                    }
                    continue;
                }

                if(!checked && !stop){
                    if(materialID == val.materialID){
                        arr.push(materialID);
                        stop = true;
                    }else{
                        arr.push(val.materialID);
                    }
                }
            }

            if(!checkedParent)arr.push(parrentID);

        }else{

            var ch = false;
            for(let i in arr){
                if(materialID == arr[i]){
                    ch = true;
                    arr.splice(i,1); 
                    for(let val of item.subMaterial){
                        for(let j in arr){
                            if(val.materialID == arr[j])arr.splice(j,1); 
                        }
                    }
                }
            }

            if(!ch){
                arr.push(materialID);
                arr.push(item.subMaterial[0].materialID); 
            }

        }

        props.setSelectedMaterials(arr);

    }

    return(
        
        <>
            {props.level == 0 ?

                <div className="row">

                    {props.materials.map((item,index) => {

                        var checked = false;
                        for(let val of props.selectedMaterials){
                            if(val == item.materialID)checked = true;
                        }
                        
                        return (
                            <div key={"i-" + index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                
                                <div className="form-group" key={"i-" + index + "-0"}>
                                    <div className="form-check form-check-inline" style={(props.level == 0 ? {paddingLeft:10}: {paddingLeft:props.level * 20 + 10})}>
                                        <input onChange={() =>  setSelectedMaterials(item,0)} className="form-check-input" type="checkbox" name="material[]" id={"material" + index + props.level} value={item.materialID} checked={checked} />
                                        <label className="form-check-label no-bold" htmlFor={"material" + index + "0"}>
                                            {item.name}
                                        </label>
                                    </div>
                                </div>

                                {item.subMaterial && <SubMaterial parentID = {item.materialID}  index={index} selectedMaterials = {props.selectedMaterials} setSelectedMaterials={props.setSelectedMaterials} allMaterials = {props.allMaterials} materials={item.subMaterial} level={props.level + 1} />}
                            </div>
                        )
                    })}


                </div>
            
            :

                <>
                    {props.materials.map((item,index) => {
                        
                        var checked = false;
                        for(let val of props.selectedMaterials){
                            if(val == item.materialID)checked = true;
                        }

                        return(
                            <Fragment key={"i-" + props.index + "-" + (index+1)} >
                                <div className="form-group">
                                    <div className="form-check form-check-inline" style={(props.level == 0 ? {paddingLeft:10}: {paddingLeft:props.level * 20 + 10})}>
                                        <input onChange={() => setSelectedMaterials(item,props.parentID)} className="form-check-input" type="checkbox" name="material[]" id={"material" + props.index + (index+1)} value={item.materialID} checked={checked} />
                                        <label className="form-check-label no-bold" htmlFor={"material" + props.index + (index+1)}>
                                            {item.name}
                                        </label>
                                    </div>
                                </div>

                                {item.subMaterial && <SubMaterial parentID = {item.materialID} index={index} selectedMaterials = {props.selectedMaterials} setSelectedMaterials={props.setSelectedMaterials} allMaterials = {props.allMaterials} materials={item.subMaterial} level={props.level + 1} />}
                            </Fragment>
                        )
                    })}

                </>   

            }

        </>
           
    );

    

}

export default SubMaterial;