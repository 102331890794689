import { DEFAULT_ASPECT_RATIO,MAX_COLUMNS,DEFAULT_NAME } from "./constans";

export const GetTracksDimensions = (width,columns) => {
    
    let tracksWidth = 0;

    if (columns > 1) {
        tracksWidth = Math.floor(width / columns);
    } else {
        tracksWidth = width;
    }

    const tracksHeight = Math.floor(tracksWidth / DEFAULT_ASPECT_RATIO);

    return {
        tracksWidth,
        tracksHeight
    }
}

export const GetColumnCount = (participantCount,windowWidth) => {
   
    
    if (windowWidth <= 576) {
        return 1;
    }

    if (windowWidth <= 768) {
        return participantCount >= 3 ? 2 : 1;
    }

    if (participantCount === 4) {
        return 2;
    }

    return Math.min(participantCount <= 6 ? 3 : participantCount <= 12 ? 4 : participantCount <= 20 ? 5 : MAX_COLUMNS, participantCount);
}

export const GetFirstLetters = (text) => {

    let letters = text.split(" ");
    let newLetters = "";

    if(letters.length > 1)
        newLetters = letters[0][0] + "" + letters[1][0];
    else
        newLetters = letters[0][0]; 

    return newLetters;

}

export const GetName = (name) => {

    return (name ? name : DEFAULT_NAME);
}