import React,{ Component,Fragment,useState } from 'react';
import {useMutation,useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import { withApollo } from 'react-apollo';
import translate from '../../Translations/index';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import {REMOVE_MEMBERSHIP,GET_CUSTOMER_DETAILS} from '../Queries/customer';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';
import moment from 'moment';


const TrialPeriodSettings = (props) => {

    const [cancelMebership, showCancelMebership] = useState(false);
    const [customerID, setCustomerID] = useState(null);

    const {data,loading:getLoading,error:getError} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
        onCompleted: (da) => {
            setCustomerID(da.customerContext.customerID);
        }
    });

    const [cancelMembership,{error,loading}] = useMutation(REMOVE_MEMBERSHIP,{
        onCompleted: () => {

            
            const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});
            var custContext = {...customerContext};

            custContext.autoPayments = 0;
                
            props.client.writeQuery({ 
                query:GET_HEADER_DATA,
                data:{
                    customerContext:custContext
                } 
            }); 

        }
    })

    const cancelMem = (act) => {

        if(act){
            cancelMembership({
                variables:{
                    customerID:customerID,
                    isTrialPeriod:true
                }
            });
        }

        showCancelMebership(false);
    }

    var err = "";
    if(error || getError){

        var helper = new Helper();
        err = helper.getApolloErrorText(error || getError);  
    }

		  
    return (
    	<div id="settings" className="whole-container">		
    		<div className="row">
    			<div className="col-sm one">
					
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t('TrialPeriod')}
                        </div>
                        <div className="card-body">

                            {err != "" ?
                                <Error text={err} />
                            :

                                (loading || getLoading ? 
                                    <Loading />
                                :
                                    (data.customerContext.lastCompletedCustomerPayment && data.customerContext.lastCompletedCustomerPayment.amount == 0 && data.customerContext.autoPayments == 1 &&  data.customerContext.lastCompletedCustomerPayment.orderID.includes("sub") ? 
                                        <>
                                            <p>{translate.t("cancelMembershipDescription")}</p>
                                            <button className="btn btn-danger btn-thiner ml-auto" onClick={() => showCancelMebership(true)}>{translate.t('CancelMembership')}</button>
                                        </>
                                    :
                                        (data.customerContext.autoPayments == 0 ?
                                            <div className="alert alert-success no-bottom-margin text-center" dangerouslySetInnerHTML={{__html:translate.t("MembershipsCanceledText") + " " + moment(data.customerContext.membershipTo).format("DD.MM.YYYY")  + " " + translate.t("MembershipsCanceledText2")}}></div>
                                        :
                                            <div className="alert alert-warning no-bottom-margin text-center">Nejsou zde žádné další informace.</div>
                                        )
                                    )
                                )

                            }

                        </div>

                        {cancelMebership && <ModalNotification biggerNo={true} yesNo = {true} text={translate.t("DoYouRealyWantToCancelMembership")} callback={cancelMem} />}
                        
                    </div>
								
    			</div>
    		</div>    			
        </div>
    );
  
}

export default withApollo(TrialPeriodSettings);
