import translate from '../../Translations/index';
import React,{forwardRef,useState,useEffect} from 'react';

const QuestionnaireCheckbox = forwardRef((props,ref) => {

    const [items, setItems] = useState([]);

    const setData = (value) => {

        var arr = [...items];

        var check = false;
        for(let i = 0; i < items.length; i++){
            if(items[i] == value){
                check = true;
                arr.splice(i,1);
            }
        }

        if(!check){
            arr.push(value);
        }

        setItems(arr);
        if(props.callBack)props.callBack(arr);
    }

    useEffect(() => {
        if(props.values){
            setItems(props.values);
        }
    },[])

    return(
        <div className="step" ref={ref}>
            <label className="input-label">{props.question}</label>
            <div className="input">
                {props.items.map((item,index) => {

                    var checked = false;
                    for(let i = 0; i < items.length; i++){
                        if(items[i] == item.questionnaireStepItemID){
                            checked = true;
                        }
                    }

                    return(
                        <div>
                            <div key={index} className="form-check form-check-inline">
                                
                                <input disabled={props.disabled} id={"ch-" + props.questionnaireStepID + "-" + item.questionnaireStepItemID} className="form-check-input" checked={checked} type="checkbox" name="newsletter" onChange={(e) => setData(item.questionnaireStepItemID)} /> 
                                
                                <label htmlFor={"ch-" + props.questionnaireStepID + "-" + item.questionnaireStepItemID} className={"form-check-label" + (checked && props.disabled ? " selected" : (props.disabled ? " normal" : ""))} >
                                    {item.text}
                                </label>
                            </div>
                        </div>
                        
                    )

                })}
            </div>
        </div>
    )

})

export default QuestionnaireCheckbox;