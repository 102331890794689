import React from 'react';
import { withApollo } from 'react-apollo';
import translate from '../../Translations/index';
import ProfileAvatar from './ProfileAvatar';

const Profile = (props) => {

    var showdata = {
        actualJob:false,
        idealJob: false,
        iAmGoodAt: false,
        aboutMe: false
    }

    for(let i = 0; i < props.customer.customerPrivacy.length; i++){
        if(props.customer.customerPrivacy[i].privacyID == 14)showdata.actualJob = true;
        if(props.customer.customerPrivacy[i].privacyID == 15)showdata.idealJob = true;
        if(props.customer.customerPrivacy[i].privacyID == 16)showdata.iAmGoodAt = true;
        if(props.customer.customerPrivacy[i].privacyID == 17)showdata.aboutMe = true;
    }

    return(
        <>
            <ProfileAvatar customer = {props.customer} aLink={false} isFluid={false} showPoints={false} inRow={false} withLink = {false} />
                        
            {!props.isVSList ?

                <>
                    <div className="card main mb-3">
                        
                        <div className="card-body">

                            <h3>Adresa</h3>
                            <p className="no-bottom-margin">
                                <strong>{props.customer.name} {props.customer.surname}</strong> <br />
                                {props.customer.street} <br />
                                {props.customer.zip}, {props.customer.city}
                            </p>

                        </div>
                    </div>               

                    <div className="card main mb-3">
                        
                        <div className="card-body">

                            <h3>Kontakt</h3>

                            <table className="table">

                                <tr><td style={{width:"130px"}}><strong>{translate.t('Email')}:</strong></td><td>{props.customer.email}</td></tr>
                                <tr><td style={{width:"130px"}}><strong>{translate.t('Phone')}:</strong></td><td>{props.customer.tel}</td></tr>

                            </table>                      
                            
                        </div>
                    </div>

                </>
            :null}

            
            <div className="card main mb-3">
                        
                <div className="card-body">

                    <h3>{translate.t("ActualJob")}</h3>
                    <p className="no-bottom-margin">{(!props.isVSList || showdata.actualJob ? props.customer.actualJob : translate.t("Unpublished"))}</p>

                </div>
            </div>
            
            <div className="card main mb-3">
                        
                <div className="card-body">

                    <h3>{translate.t("IdealJob")}</h3>
                    <p className="no-bottom-margin">{(!props.isVSList || showdata.idealJob ? props.customer.idealJob : translate.t("Unpublished"))}</p>

                </div>
            </div>
            
            <div className="card main mb-3">
                        
                <div className="card-body">

                    <h3>{translate.t("IamGoodAt")}</h3>
                    <p className="no-bottom-margin">{(!props.isVSList || showdata.iAmGoodAt ? props.customer.iAmGoodAt : translate.t("Unpublished"))}</p>

                </div>
            </div>
            
            <div className="card main mb-3">
                        
                <div className="card-body">

                    <h3>{translate.t("AboutMe")}</h3>
                    <p className="no-bottom-margin pre-wrap" dangerouslySetInnerHTML={{__html:(!props.isVSList || showdata.aboutMe ? props.customer.aboutMe : translate.t("Unpublished"))}}></p>

                </div>
            </div>
           

        </>
                
                
    );

    

}

export default withApollo(Profile);