import Notification from '../../../Library/notification';
import moment from 'moment';
import { GET_GUIDE_RESERVATION_DAY_DATA,GET_GUIDE_RESERVATIONS_BY_DATE } from '../../Reservations/Queries/guideReservation';

class Room {

    constructor(props){
        this.props = props;
    }

    setConnection(socket,customerData,roomNumber,setRoomControllData,SetShowReservationTimes){
        
        socket.on('disconnect', (reason) => {

            if(reason == "transport error" || reason == "ping timeout" || reason == "transport close"){

                setRoomControllData(prevValue => {

                    if(prevValue.openRoom == true && (prevValue.isModerator || !prevValue.showKnockButton)){

                        var dataToSend = {
                            roomNumber:roomNumber,
                            user:{
                                customerID:         customerData.customerID,
                                name:               customerData.name,
                                surname:            customerData.surname,
                                astralName:         customerData.astralName,
                                roomName:           customerData.roomName,
                                roomTitle:          this.getRoomName(roomNumber),
                                customerTypeID:     customerData.customerTypeID,
                                hasApprovalToRoom:  (prevValue.isModerator ? true : prevValue.hasApprovalToRoom),
                                isModerator:        prevValue.isModerator
                            }
                        }
                        socket.emit('join-room', dataToSend);

                    }else{
                        socket.emit('is-room-open', roomNumber)
                    }

                    var newValue = {...prevValue};
                    newValue.socket = socket;

                    return newValue;

                })
            }
        });

        socket.on("disconnect-user", (data) => {

            var showReloadButton = false;
            if(!data.isRoomOpen){
                showReloadButton = true;
            }
            SetShowReservationTimes(false);
            this.disconnectVideo(data.isModerator,setRoomControllData,showReloadButton,"ON Disconnetc-user");
        });

        return socket;
    }

    isRoomOpened(socket,roomNUmber){
        socket.emit('is-room-open', roomNUmber);
    }

    receiveAllGuideReservations(socket,setRoomControllData)
    {
        socket.on("all-guide-reservations", async (data) => {

            setRoomControllData((prevValue) => {
                
                var newValue = {...prevValue};
                newValue.allGuideReservations = data;

                return newValue;

            })
        })
    }

    receiveIsRoomOpened(socket,roomNumber,customerData,setRoomControllData,SetShowReservationTimes){
        
        socket.on("is-room-open", async (data) => {

            var isModerator = (data.value == true && data.customerID && data.customerID == customerData.customerID ? true : false);
            var resTimesData;

            if(data.value && (data.customerID || data.moderatorData))
            {
                resTimesData = await this.props.client.query({
                    query:GET_GUIDE_RESERVATION_DAY_DATA,
                    fetchPolicy: 'network-only',
                    variables:{
                        day:moment().isoWeekday(),
                        guideID: data.customerID || data.moderatorData.customerID,
                        customerID: customerData.customerID,
                        date: moment().format("YYYY-MM-DD")
                    }
                })

                if(isModerator)
                {
                    var allReservationsByDate = await this.props.client.query({
                        query:GET_GUIDE_RESERVATIONS_BY_DATE,
                        fetchPolicy: 'network-only',
                        variables:{
                            guideID: data.customerID || data.moderatorData.customerID,
                            date: moment().format("YYYY-MM-DD")
                        }
                    })
                    socket.emit('all-guide-reservations', {
                        roomNumber: roomNumber,
                        reservations:allReservationsByDate.data.GuideReservationsByDate
                    });
                }

            }

            if(data.value == true){

                var dataToSend = {
                    roomNumber:roomNumber,
                    user:{
                        customerID:         customerData.customerID,
                        name:               customerData.name,
                        surname:            customerData.surname,
                        astralName:         customerData.astralName,
                        roomName:           customerData.roomName,
                        roomTitle:          this.getRoomName(roomNumber),
                        customerTypeID:     customerData.customerTypeID,
                        hasApprovalToRoom:  (isModerator ? true : false),
                        isModerator:        isModerator
                    }
                }

                if(
                    isModerator || 
                    (data.customerID && resTimesData && resTimesData.data.GuideReservationDayDataForRoom.length == 0) || 
                    (data.customerID && resTimesData && resTimesData.data.HasCustomerReservationWithGuide > 0)
                )
                    socket.emit('join-room', dataToSend);
                else if(data.customerID)
                    SetShowReservationTimes(true);

            }

            setRoomControllData(prevRoomControllData => {

                if(prevRoomControllData.openRoom == true && data.value == false){

                    SetShowReservationTimes(false);
                    this.disconnectVideo(isModerator,setRoomControllData,null," ON is-room-open");
                    return prevRoomControllData;

                }else{

                    var newValue = {...prevRoomControllData};

                    newValue.isModerator            = isModerator;
                    newValue.openRoom               = data.value;
                    newValue.firstInitSocket        = true;
                    newValue.showKnockButton        = (data.value == true ? (isModerator || (data.customerID && resTimesData && resTimesData.data.HasCustomerReservationWithGuide > 0) || (data.customerID && resTimesData && resTimesData.data.GuideReservationDayDataForRoom.length == 0) ? false : true) : false);
                    newValue.resTimesData           = resTimesData ? resTimesData.data : null;
                    newValue.guideID                = data.customerID || data.moderatorData && data.moderatorData.customerID;

                    return newValue;
                }
            })
        });
    }

    doKnock(roomNumber,customerData,setRoomControllData,SetShowReservationTimes)
    {
        setRoomControllData((prevData) => {

            if(
                prevData.resTimesData && 
                prevData.resTimesData.GuideReservationDayDataForRoom.length > 0 &&
                prevData.resTimesData.HasCustomerReservationWithGuide == 0
            )
            {
                SetShowReservationTimes(true);
                return prevData;
            }
            else
            {
                this.JoinRoom(roomNumber,customerData,setRoomControllData);

                var newValue = {...prevData};
                newValue.showKnockButton = false;

                return newValue;
            }
        })

    }

    JoinRoom(roomNumber,customerData,setRoomControllData,SetShowReservationTimes)
    {
        if(SetShowReservationTimes)
            SetShowReservationTimes(false);

        setRoomControllData((prevData) => {

            var dataToSend = {
                roomNumber:roomNumber,
                user:{
                    customerID:         customerData.customerID,
                    name:               customerData.name,
                    surname:            customerData.surname,
                    astralName:         customerData.astralName,
                    roomName:           customerData.roomName,
                    roomTitle:          this.getRoomName(roomNumber),
                    customerTypeID:     customerData.customerTypeID,
                    hasApprovalToRoom:  false,
                    isModerator:        false
                }
            }
            
            prevData.socket.emit('join-room', dataToSend);

            var newValue = {...prevData};
            newValue.showKnockButton = false;

            return newValue; 
        })
    }

    receiveAllUsers(socket,setAllUsers,setAllWaitingUsers){
        socket.on("all-room-users", (data) => {
            setAllUsers(data.users);
            setAllWaitingUsers(data.waitingUsers);
        });
        
    }

    setOpenRoom(setRoomControllData,roomNumber,value,customerID){

        setRoomControllData((prevData) => {
            prevData.socket.emit('open-room', {
                roomNumber,
                value,
                customerID,
                roomName:this.getRoomName(roomNumber)
            });
            return prevData;
        })
    }

    approveEnter(setRoomControllData,customerID){

        setRoomControllData((prevData) => {
            prevData.socket.emit('approve-enter', {customerID});
            return prevData;
        })
    }

    removeUser(setRoomControllData,customerID){

        setRoomControllData((prevData) => {
            prevData.socket.emit('remove-user', {customerID});
            return prevData;
        })
        
    }

    receiveApproveEnter(socket,callBack){
       
        socket.on("approve-enter", (data) => {
            if(data && callBack)callBack(true);
        });
    }

    receiveNewApprovalNotification(socket){
       
        socket.on("new-approval", (data) => {

            let audio = new Audio("/Sound/door-knock-sound-effect.mp3");
            audio.play();
            
            let notify = new Notification();
            notify.setNotification(null,(data.astralName != "" ? data.astralName : (data.roomName != "" ? data.roomName : data.name + " " + data.surname)) + " žádá o přijetí",true,false,this.props.client);
            
        });
    }

    AudioMuteStatusChanged(data,apiJItsi,setRoomControllData)
    {
        setRoomControllData((prevValue) => {

            const numberOfParticipants = apiJItsi.getNumberOfParticipants();
            if(numberOfParticipants >= 10 && !prevValue.isModerator)
            {
                if(data.muted)
                    apiJItsi.executeCommand('setVideoQuality', 180);
                else
                    apiJItsi.executeCommand('setVideoQuality', 720);
            }
            return prevValue;
        })
    }

    ParticipantJoined(apiJItsi,setRoomControllData)
    {
        apiJItsi.isAudioMuted().then(muted => {

            setRoomControllData((prevValue) => {

                const numberOfParticipants = apiJItsi.getNumberOfParticipants();
                if(numberOfParticipants >= 10 && muted && !prevValue.isModerator)
                {
                    apiJItsi.executeCommand('setVideoQuality', 180);
                }

                return prevValue;

            })     
        });  
    }

    SetApiListeners(apiJItsi,setRoomControllData)
    {
        apiJItsi.addListener("audioMuteStatusChanged", (data) => this.AudioMuteStatusChanged(data,apiJItsi,setRoomControllData));
        apiJItsi.addListener("participantJoined", () => this.ParticipantJoined(apiJItsi,setRoomControllData));
    }

    offEvents(socket){
        socket.removeAllListeners("new-approval");
        socket.removeAllListeners("all-room-users");
        socket.removeAllListeners("is-room-open");
        socket.removeAllListeners("disconnect-user");
        socket.removeAllListeners("disconnect");
        socket.removeAllListeners("approve-enter");
        socket.removeAllListeners("all-guide-reservations");
    }

    disconnectVideo(isModerator,setRoomControllData,showReloadButton,onString){
                        
        setRoomControllData((prevValue) => {

            clearInterval(prevValue.timeInterval);

            var newValue = {...prevValue};

            clearInterval(newValue.timeInterval);

            if(newValue.api){
                newValue.api.executeCommand('hangup');
                newValue.api.removeListener("audioMuteStatusChanged", (data) => this.AudioMuteStatusChanged(data,newValue.api,setRoomControllData));
                newValue.api.removeListener("participantJoined", () => this.ParticipantJoined(newValue.api,setRoomControllData));
                newValue.api.dispose();
            }

            newValue.socket.emit("disconnect-user","z funkce disconnectVideo - " + onString);

            this.offEvents(newValue.socket);

            newValue.api                    = null;
            newValue.socket                 = null;
            newValue.openRoom               = false;
            newValue.isModerator            = false;
            newValue.firstInitSocket        = false;
            newValue.hasApprovalToRoom      = false;
            newValue.showReloadButton       = false;
            newValue.showKnockButton        = true;
            newValue.showReloadButton       = (showReloadButton ? true : false);
            newValue.time                   = 0;
            newValue.timeInterval           = null;
            newValue.resTimesData           = null;
            newValue.guideID                = null;
            newValue.allGuideReservations   = null;
            
            return newValue;

        })
 
    }

    getRoomName(hash){

        var roomName = "";

        switch(hash){
            case "1g5fd6g41df6g541dg48641sd5456446": 
                roomName   = "Místnost 1";
                break;
            case "ac8beae3ef071ab806a50caaaf248285": 
                roomName   = "Místnost 2";
                break;
            case "25666d844c2c6c6dfacac8dda39c0021": 
                roomName   = "Místnost 3";
                break;
            case "57d830344e1e862b8195b72e22a652a0": 
                roomName   = "Místnost 4";
                break;
            case "gd84h8s6s9h14j78s6s4gs518goi5ssf": 
                roomName   = "Místnost 5";
                break;
            case "bbf3d1e8866defe9b5b63c7fd2de6e88":
                roomName   = "Aula Astrální akademie";
                break;
            default: 
                roomName = "";
        } 

        return roomName;

    }

}

export default Room
