import React,{Fragment, useEffect, useRef, useState} from 'react';
import arrows from '../../../Public/Images/Icons/arrows_down.webp';

const SubMaterial = (props) => {

    const ulRef = useRef();
    const [open, SetOpen] = useState(props.selectedDegree);
    const [includeSelMat, SetIncludeSelMat] = useState(true);
    const [height,SetHeight] = useState(0);

    const setMaterial = (e,item) => {
        e.preventDefault();
        //SetIncludeSelMat(true);
        props.setMaterialContent(item.description);
        props.setSelectedMaterial(item.materialID);
        props.setOpenSidebar(false);
        props.setLessonName(props.lessonNames + " — " + item.name);
    }

    const Open = (materialID) => {
        if(materialID != open)
            SetOpen(materialID);
        else
            SetOpen(0);
       // SetIncludeSelMat(false);
    }

    useEffect(() => {
        if(props.open)
            SetHeight(ulRef.current.offsetHeight);
        else
            SetHeight(0);
    },[props.open])
    

    return(
        
        <>
            {props.level == 0 ?

                <>

                    {props.materials.map((item,index) => {

                        var check = false;
                        var selected = false;
                        if(open == item.materialID)check = true;

                        for(let val of item.subMaterial)
                            if(props.selectedMaterial == val.materialID)
                            {
                                selected = true;
                                //if(includeSelMat)check = true;
                            }

                        return (
                            <Fragment key={index}>
                                <div className={"header" + (selected ? " selected" : "")} onClick={() => Open(item.materialID)}>
                                    <h3>{item.name}</h3>
                                    <img className={"ml-auto" + (check ? " rotate" : "")} src={arrows} />
                                </div>
                                {item.subMaterial && <SubMaterial selectedDegree = {props.selectedDegree} open={check} lessonNames = {item.name} setLessonName={props.setLessonName} setOpenSidebar={props.setOpenSidebar} selectedMaterial={props.selectedMaterial} setSelectedMaterial={props.setSelectedMaterial} setMaterialContent={props.setMaterialContent} materials={item.subMaterial} level = {props.level + 1} />}
                            </Fragment>
                        )
                    })}


                </>
            
            :
                <div className="material-section" style={(height != 0 ? {height: height + "px"} : {height: "0px"})}>
                    <ul ref={ulRef}>
                        {props.materials.map((item,index) => {

                            return (
                                <Fragment key={index}>
                                    <li className={(props.selectedMaterial == item.materialID ? "selected" : "")}>
                                        <a href="" onClick={(e) => setMaterial(e,item)}>{item.name}</a>
                                        {item.subMaterial && <SubMaterial lessonNames = {props.lessonNames + " | " + item.name} setLessonName={props.setLessonName} setOpenSidebar={props.setOpenSidebar} selectedMaterial={props.selectedMaterial} setSelectedMaterial={props.setSelectedMaterial} setMaterialContent={props.setMaterialContent} materials={item.subMaterial} level = {props.level + 1} />}
                                    </li>
                                    
                                </Fragment>
                            )
                        })}
                    </ul>
                </div>

            }

        </>
           
    );

    

}

export default SubMaterial;