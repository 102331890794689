/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_EVENTS = gql`
    query AllEvents($lang: String!,$offset:Int, $limit: Int, $onlyFuture:Boolean){
        allEvents(lang: $lang,offset:$offset,limit:$limit,onlyFuture:$onlyFuture){  
            eventID
            eventDate
            title
            niceTitle
            text
            lang
            active
        }
        allEventsCount(onlyFuture:$onlyFuture)
        customerContext{
            customerID
            membershipTo
            regCode
            lastCompletedCustomerPayment{
                amount
                dateFrom
                dateTo
            }
        }
    }
`;

export const GET_EVENT_CATEGORIES = gql`
    query AllEventCategorys($lang: String!){
        allEventCategorys(lang: $lang){
            eventCategoryID
            name
            niceName
            active
            lang
            text
        }
    }
`;

export const UNSUBSCRIBE_CATEGORY_EVENT = gql`
    mutation UnsubscribeCategoryEvent($categoryEventIDs: [ID]){
        unsubscribeCategoryEvent(categoryEventIDs: $categoryEventIDs)
    }
`;