import { useEffect, useRef, useState } from "react";
import { GetTracksDimensions,GetColumnCount, GetName } from "./Functions";
import { SPY_NAME } from "./constans";

var JitsiMeetJS;
var connection;
var room;

const UseObserver = (options,roomName) => {

    const [error, SetError] = useState(null);
    const [participants, SetParticipants] = useState([]);
    const [tracksDimensions, SetTracksDimensions] = useState({
        tracksWidth:0,
        tracksHeight:0,
        columns:1
    });

    useEffect(() => {

        CalculateTracksDimensions();

        window.addEventListener("resize", CalculateTracksDimensions);
        return () => {
            window.removeEventListener("resize", CalculateTracksDimensions);
        }
        

    },[participants])

    const CalculateTracksDimensions = () => {

        if(participants.length > 0)
        { 
            let widthOffset = 330;
            let windowWidth = document.children[0].offsetWidth;

            if(windowWidth <= 1500) widthOffset = 310;
            if(windowWidth <= 991) widthOffset = 60;
            if(windowWidth <= 767) widthOffset = 30;

            let width   = windowWidth - widthOffset;
            let columns = GetColumnCount(participants.length,windowWidth);

            const {tracksWidth, tracksHeight} = GetTracksDimensions(width,columns);

            SetTracksDimensions({
                tracksWidth,
                tracksHeight,
                columns
            })
        }  
    }

    const InitConnection = (newJitsiMeetJS) => {
        
        const initOptions = {
            disableAudioLevels: true,
            startWithAudioMuted:true,
            startWithVideoMuted:true,
        };
        
        newJitsiMeetJS.init(initOptions);

        connection = new newJitsiMeetJS.JitsiConnection(null, null, options);
        connection.addEventListener(newJitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, OnConnectionSuccess);
        connection.addEventListener(newJitsiMeetJS.events.connection.CONNECTION_FAILED, OnConnectionFailed);
        connection.connect();

        JitsiMeetJS = newJitsiMeetJS;
    }

    const OnConnectionSuccess = (data) => {

        const confOptions = {
        };

        room = connection.initJitsiConference(roomName, confOptions);
        room.setDisplayName("L1#_!(o.o)!_#9S");

        room.on(JitsiMeetJS.events.conference.TRACK_ADDED, OnRemoteTrack);
        room.on(JitsiMeetJS.events.conference.USER_LEFT, OnUserLeft);
        room.on(JitsiMeetJS.events.conference.USER_JOINED, (id,user) => {
            AddParticipant(null,id,user.getDisplayName());
        });
        room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, RemoveTrack);
        room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, OnConferenceFailed);

        room.join();

    }

    const OnConferenceFailed = (c) => {
        if(c === "conference.authenticationRequired")
        {
            Disconnect();
            setTimeout(() => {
                console.warn("reconect");
                InitConnection(window.JitsiMeetJS);
            },2000)
        }
    }

    const OnConnectionFailed = (d) => {
        if(d === "connection.otherError")
        {
            SetError("Pravděpodobně není spuštěn server s konferencí.");
        }
    }

    const Disconnect = () => {

        if(room && connection)
        {
            room.off(JitsiMeetJS.events.conference.USER_LEFT, OnUserLeft);
            room.off(JitsiMeetJS.events.conference.TRACK_REMOVED, RemoveTrack);

            room.leave();

            connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,OnConnectionSuccess);
            connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED,OnConnectionFailed);
            connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,Disconnect);
        
            connection.disconnect();

            room = null;
            connection = null;
            JitsiMeetJS = null;  
        } 
    }

    const OnUserLeft = (id) => {
        RemoveParticipant(id);    
    }

    const OnRemoteTrack = (track) => {

        if (track.isLocal()) {
            return;
        }
        const participantID = track.getParticipantId();

        var element = null;
        if (track.getType() === 'video') {
            element = document.createElement("video");
            element.setAttribute("autoplay","1");
            element.setAttribute("id",participantID + "video");
        }
        else
        {
            element = document.createElement("audio");
            element.setAttribute("autoplay","1");
            element.setAttribute("id",participantID + "audio");
        }

        AddParticipant(track,participantID,"");
    } 

    const AddParticipant = (track,participantID,name) => {

        if(GetName(name) !== SPY_NAME)
        {
            SetParticipants((prevParticipants) => {

                var newParticipants = [...prevParticipants];

                var check = false;
                for(let i in newParticipants)
                {
                    if(newParticipants[i].participantID == participantID)
                    {
                        if(track)
                        {
                            newParticipants[i] = {...newParticipants[i],tracks:[...newParticipants[i].tracks,track]}
                        }
                        check = true;
                    }
                }

                if(!check)
                    newParticipants.push({
                        participantID:participantID,
                        name:name,
                        tracks:(track ? [track] : [])
                    })

                return(newParticipants);
            })
        }
        
    }
    
    const RemoveParticipant = (id) => {

        SetParticipants((prevParticipants) => {

            var newParticipants = [...prevParticipants];

            for(let i in newParticipants)
            {
                if(newParticipants[i].participantID == id)
                    newParticipants.splice(i,1);
            }

            return newParticipants;

        })
    }

    const RemoveTrack = (track) => {

        SetParticipants((prevParticipants) => {

            const id = track.getParticipantId();

            var newParticipants = [...prevParticipants];

            for(let i in newParticipants)
            {
                if(newParticipants[i].participantID == id)
                {
                    let tracks = [...newParticipants[i].tracks];
                    for(let i in tracks)
                    {
                        if(tracks[i].getType() === track.getType())
                            tracks.splice(i,1);
                    }
                    newParticipants[i] = {...newParticipants[i],tracks:tracks}
                }
            }

            return newParticipants;

        })
    }

    return {
        error,
        participants,
        tracksDimensions,
        InitConnection,
        Disconnect,
        SetParticipants
    }

}

export default UseObserver;