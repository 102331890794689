import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './Public/Css/main.css';
import './Public/Css/xlg.css';
import './Public/Css/lg.css';
import './Public/Css/md.css';
import './Public/Css/sm.css';
import './Public/Css/xs.css';

import './Public/Css/theme_dark.css';

import 'emoji-mart/css/emoji-mart.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import {IntlProvider/*,addLocaleData*/} from 'react-intl';
//import csLocaleData from 'react-intl/locale-data/cs';

//addLocaleData([...csLocaleData]);

ReactDOM.render((
    <IntlProvider locale="cs">
        <BrowserRouter>
                <App />
        </BrowserRouter>
    </IntlProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
