/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../../../Public/Images/logo.svg';
import emailIcon from '../../../Public/Images/Icons/email_24.webp';
import key from '../../../Public/Images/Icons/key.webp';
import translate from '../../Translations/index';
import {Mutation} from 'react-apollo';
import {REGISTER_NEW_CUSTOMER} from '../Queries/registration';
import Helper from '../../../Library/helper';

const Registration = (props) => {

    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [repeatPassword,setRepeatPassword] = useState("");
    const [newsletter, setNewsletter] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [personal, setPersonal] = useState(false);
    const [error,setError] = useState("");
    const [success,setSuccess] = useState("");

    /* affregCode se teď nepoužívá přes regisrtraci ale nechal jsem to tady zakomentované
    
    var affRegCode = props.match.params.affCode;
    if(!affRegCode){

        var affFromLocal = localStorage.getItem("affRegCode");
        if(!affFromLocal)affRegCode = "";
        else affRegCode = affFromLocal;

    }else{
        localStorage.setItem("affRegCode",affRegCode);
    }*/

    var hostname = window.location.hostname;
    hostname = hostname.split(".");

    const checkRegistration = (register) => {

        var helper = new Helper();

        if(password !== '' && email !== '' && repeatPassword !== ''){

            if(helper.validateEmail(email)){
    
                if(password == repeatPassword){

                    if(personal){

                        //if(confirm){

                            localStorage.removeItem("affRegCode");

                            register({variables:{
                                customerData:{
                                    name:"",
                                    surname:"",
                                    street:"",
                                    city:"",
                                    zip:"",
                                    tel:"",
                                    countryID:60,
                                    email,
                                    password,
                                    membershipDuration:0,
                                    questionnaireID:1,
                                    courseID:0,
                                    personal,
                                    newsletter,
                                    affRegCode:""
                                },
                                domain:(hostname.length > 1 ? hostname[1] + "." + hostname[2] : hostname[0])
                            }});  
                        /*        
                        }else{
                            setError(translate.t('NotConfirmedHealth'));
                            setTimeout(() => setError(""),3000);
                        } */

                    }else{
                        setError(translate.t('PersonalConditions'));
                        setTimeout(() => setError(""),3000);
                    }
                
                }else{
                    setError(translate.t('NotSamePasswords'));
                    setTimeout(() => setError(""),3000);
                }

            }else{
                setError(translate.t('EmailInWrongFormat'));
                setTimeout(() => setError(""),3000);
            }
          
        }else{
            setError(translate.t('NotFilledEmailOrPwd'));
            setTimeout(() => setError(""),3000);
        }
    
    }
		   		  
    return (
        <div id="login" className="admin">
            <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>

        	<div className="card">
				
				<div className="card-header">{translate.t('AstralProjection')}</div>
				<div className="card-body">

                    {success ?
                        <div className="alert alert-success mb-0">{success}</div>
                        :
   
                        <form>

                            <p className="reg-title">
                                <strong>Ahoj a vítej v Astrální akademii</strong>
                            </p>
                            <p className="f-13 text-left">Ať už se k nám jdeš připojit na živá vysílání, nebo sis zakoupil záznam z webinářů - věříme, že se u nás budeš cítit jako doma.</p>
                            <p className="f-13 text-left">Astrální akademie je bezpečný prostor, kde každý může být svůj ve své autenticitě a zářit jako nejjasnější Hvězda. </p>
                            <p className="f-13 text-left">Vítej doma, vítej mezi svými🌟</p>

                            <div className="form-group">
                                
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" ><img alt="" src={emailIcon} /></span>
                                </div>
                                <input
                                    onChange={event => setEmail(event.target.value)}
                                    value={email}
                                    type="text" className="form-control" placeholder={translate.t('Email')} aria-label="Username" />
                                </div>
        
                            </div>
                            <div className="form-group">
                                                        
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" ><img alt="" src={key} /></span>
                                </div>
                                <input
                                    onChange={event => setPassword(event.target.value)}
                                    value={password}
                                    type="password" placeholder={translate.t('Password')} className="form-control" aria-label="Password" />
                                </div>
                                
                            </div>

                            <div className="form-group">
                                                        
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" ><img alt="" src={key} /></span>
                                </div>
                                <input
                                    onChange={event => setRepeatPassword(event.target.value)}
                                    value={repeatPassword}
                                    type="password" placeholder={translate.t('RepeatPassword')} className="form-control" aria-label="Password" />
                                </div>
                                
                            </div>

                            <div className="text-left">
                                <div className="form-check form-check-inline no-margin-right">
                                    <input id="personal" value={personal} checked={personal} type="checkbox" className="form-check-input" onChange={(e) => setPersonal(e.target.checked)} />
                                    <label htmlFor="personal" className="f-13 form-check-label" >
                                        {translate.t('AgreeWith')} <a target="_blank" href="https://www.astralniakademie.cz/obchodni-podminky" >{translate.t("TermsAndConditions")}</a> {translate.t("AndWith")} <a target="_blank" href="https://www.astralniakademie.cz/ochrana-osobnich-udaju">{translate.t("ProcessingMyPersonalData")}</a>
                                    </label>
                                </div>
                                
                            </div>	

                            <div className="form-group text-left">
                                <div className="form-check form-check-inline no-margin-right">
                                    <input id="newsletter" value={newsletter} checked={newsletter} type="checkbox" className="form-check-input" onChange={(e) => setNewsletter(e.target.checked)} />
                                    <label htmlFor="newsletter" className="f-13 form-check-label" >
                                        {translate.t('NewsletterAgree')}
                                    </label>
                                </div>
                                
                            </div>

                            {false &&  
                                <div className="form-group text-left">
                                    <div className="form-check form-check-inline align-items-start no-margin-right">
                                        <input id="confirm" value={confirm} checked={confirm} type="checkbox" className="mt-1 form-check-input" onChange={(e) => setConfirm(e.target.checked)} />
                                        <label htmlFor="confirm" className="f-13 form-check-label" >
                                            {translate.t('ConfirmMyHealth')}
                                        </label>
                                    </div>
                                    
                                </div>   
                            }  		
                            
                            <div>
                            
                                <Mutation
                                    mutation={REGISTER_NEW_CUSTOMER}
                                    onCompleted={async (data) => {

                                        if(data.register.error != ""){
                                            setError(data.register.error);
                                            setTimeout(() => setError(""),3000);
                                        }else{  
                                            setSuccess(translate.t("SuccessRegistration")); 
                                        }
                                    }}
                                    >
                                    {(registration, { loading, error }) => {
                                        // this loading state will probably never show, but it's helpful to
                                        // have for testing
                                        if (loading) return "";
                                        if (error){
                                            setError(error);
                                            return "";
                                        }

                                        return <button type="button" onClick={() => checkRegistration(registration)} className="btn btn-primary btn-blue btn-block">{translate.t('DoRegistration')}</button>;
                                    }}
                                </Mutation>
                                <p className="mt-2 text-center"><a href="/">{translate.t("ENTER_FOR:REGISTERED")}</a></p>
                                
                            </div>
                            
                            {error && <div className="alert alert-danger mt-4 mb-0">{error}</div>}
                            

                        </form>
                    }
	        	</div>
        	</div>
        </div>
    );
  
}

export default Registration;
