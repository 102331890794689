import { useRef,useEffect, useState } from "react";
import translate from '../../Translations/index';
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { useMutation } from "react-apollo";
import { DELETE_CHAT_MESSAGE } from "../Queries/customer";
import { UseSocket } from "../../Socket/Components/Socket";
import {withApollo} from 'react-apollo';
import Success from "../../../GlobalComponents/Success";
import trash from '../../../Public/Images/Icons/trash.webp';
import copy from '../../../Public/Images/Icons/copy.webp';

const ChatMessageOptions = ({client,groupID,isVs,messageText,scrollAfterUpdateMessages,setAllMessages,allMessages,showOption,SetShowOption,position,customerChatMessagesID}) => {

    const ref = useRef();
    const [showNotifyType, SetShowNotifyType] = useState(0);
    const [isCopied, SetIsCopied] = useState(false);
    const {SendDeleteChatMessage} = UseSocket();

    const notifyText = [
        translate.t("DeleteOnlyAtMeInfo"),
        translate.t("DeleteAtEveryoneInfo"),
        translate.t("DeleteOnlyAtTheyInfo")
    ]

    useEffect(() => {

        if(showOption && ref && ref.current)
        {
            ref.current.addEventListener("wheel",MouseScroll);
            ref.current.addEventListener("touchmove",MouseScroll);

            return () => {
                if(ref.current){
                    ref.current.removeEventListener("wheel",MouseScroll);
                    ref.current.removeEventListener("touchmove",MouseScroll);
                }
            }
        }

    },[showOption,ref]);

    const [deleteChatMessage] = useMutation(DELETE_CHAT_MESSAGE,{
        onCompleted: (data) => {
            
            var arr = [...allMessages];
            for(let i = 0; i < allMessages.length; i++)
            {
                if(allMessages[i].customerChatMessagesID == data.deleteChatMessage.customerChatMessagesID)
                {
                    if(data.deleteChatMessage.type == 3)
                    {
                        arr[i] = {...arr[i],deletedAtThey:1}
                    }
                    if(data.deleteChatMessage.type == 2)
                    {
                        arr[i] = {...arr[i],deleted:1}
                        SendDeleteChatMessage({
                            customerChatMessagesID,
                            deleted:1
                        })
                    }
                    if(data.deleteChatMessage.type == 1)
                    {
                        arr[i] = {...arr[i],deletedAtMe:1}   
                    }      
                }
            }

            SetShowOption(false);
            
            scrollAfterUpdateMessages.current = false;

            setAllMessages(arr);
           
            
        },
        onError:(error) => {
            console.log(error);
            
        }
    })

    const MouseScroll = (e) => {
        e.stopPropagation();
    }

    const HandleContextMenu = (e) => {

        e.persist();
        e.preventDefault();
        e.stopPropagation();
        
    }

    const DeleteMessage = (act) => {
        if(act)
        {
            deleteChatMessage({
                variables:{
                    customerChatMessagesID,
                    type:showNotifyType,
                    groupID:(groupID && isVs ? groupID : 0)
                }
            })
        }

        SetShowNotifyType(0);
    }

    const CopyText = () => {
        
        if(messageText)
        {
            navigator.clipboard.writeText(messageText).then(() => {

                SetIsCopied(true);
                setTimeout(() => {
                    SetShowOption(false);
                    SetIsCopied(false);
                },2000);
                
            });
        }
    }

    return(
        <>
            <div ref = {ref} onClick={() => SetShowOption(false)} onContextMenu={(e) => HandleContextMenu(e)} className="message-options-container">
                <div onClick={(e) => e.stopPropagation()} onContextMenu={(e) => e.stopPropagation()} className="message-options card" style={{left: position.x + "px",top: (position.y)+"px" }}>
                    
                    <div className="card-header">{translate.t("Options")}</div>
                    <div className="card-body">
                        {!isVs ?
                            <>
                                <div className="option" onClick={() => SetShowNotifyType(1)}>
                                    <img src={trash} />
                                    {translate.t("DeleteOnlyAtMe")}
                                </div>
                                <div className="option" onClick={() => SetShowNotifyType(2)}>
                                    <img src={trash} />
                                    {translate.t("DeleteAtEveryone")}
                                </div>
                            </>
                        :
                            <div className="option" onClick={() => SetShowNotifyType(3)}>
                                <img src={trash} />
                                {translate.t("DeleteOnlyAtMe")}
                            </div>
                        }

                        {messageText ?
                            <div className="option last" onClick={() => CopyText()}>
                                {isCopied ?
                                    <Success className="no-top-margin no-border" text={translate.t("COPIED")} thiner={true} />
                                :
                                    <>
                                        <img src={copy} />
                                        {translate.t("CopyText")}
                                    </>
                                }
                            </div>
                        :null}

                    </div>
                    
                </div>
            </div>
            {showNotifyType != 0 ?
                <ModalNotification callback={DeleteMessage} text={notifyText[showNotifyType - 1]} yesNo={true} />
            :null}
        </>
    )
}

export default withApollo(ChatMessageOptions);